import React, { useState, useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import { useCookies } from "react-cookie";
import toast, { Toaster } from "react-hot-toast";
import Axios from "axios";
const apiUrl = process.env.REACT_APP_BACKEND_URL;
Axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
Axios.defaults.xsrfCookieName = "csrftoken";
Axios.defaults.withCredentials = true;
const Header = () => {
  const [activepg, setActivePg] = useState("home");
  const [data, setData] = useState({});
  const [cookies, setCookie, removeCookie] = useCookies(["csrftoken"]);
  const [isUserLogged, setIsUserLogged] = useState(false);
  //Verify user login
  useEffect(() => {
    const config = {
      headers: { "content-type": "application/json" },
    };

    Axios.get(`${apiUrl}/user`, {}, config)
      .then((response) => {
        setData(response.data.user);
        setIsUserLogged(true);
      })
      .catch((error) => {
        setIsUserLogged(false);
      });
  }, []);

  // Vrrify User Login End========>

  function logout() {
    const config = {
      headers: { "content-type": "application/json" },
    };

    Axios.post(`${apiUrl}/logout`, {}, config)
      .then((response) => {
        removeCookie("csrftoken");
        window.location.href = "/";
      })
      .catch((error) => {
        toast.error("Une erreur s'est produite ");
      });
  }

  return (
    <>
      <Toaster />

      <nav class="navbar navbar-expand-lg navbar-light bg-light mb-0 p-2  position-sticky top-0 left-0 w-100 shadow-sm" style={{ zIndex: "999" }}>
        <div class="container-fluid">
          <a class="navbar-brand pe-4" href="/app/">
            <h6 className="fs-4 fw-bold my-0 py-0"> Ecoolo </h6>
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            {isUserLogged ? (
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <Link to="/app/" class={`nav-link ${activepg == "home" ? "active" : ""}`} aria-current="page" onClick={() => setActivePg("home")}>
                    Consommations
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="/app/evolutions" class={`nav-link ${activepg == "evolution" ? "active" : ""}`} aria-current="page" onClick={() => setActivePg("evolution")}>
                    Statistiques
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="/app/projection" class={`nav-link ${activepg == "simulation" ? "active" : ""}`} aria-current="page" onClick={() => setActivePg("simulation")}>
                    Simulation
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="https://blog.ecoolo.fr" target="_blank" rel="noopener noreferrer" class={`nav-link ${activepg == "details" ? "active" : ""}`} aria-current="page" >
                    Blog
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="/details" class={`nav-link ${activepg == "details" ? "active" : ""}`} aria-current="page" onClick={() => setActivePg("details")}>
                    Détails
                  </Link>
                </li>
                {data.is_staff && (
                  <li class="nav-item">
                    <Link to="/app/admin" class={`nav-link ${activepg == "admin" ? "active" : ""}`} aria-current="page" onClick={() => setActivePg("admin")}>
                      Admin
                    </Link>
                  </li>
                )}
              </ul>) : (
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <Link to="/" class={`nav-link ${activepg == "home" ? "active" : ""}`} aria-current="page" onClick={() => setActivePg("home")}>
                    L'application
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="/details" class={`nav-link ${activepg == "details" ? "active" : ""}`} aria-current="page" onClick={() => setActivePg("details")}>
                    Détails
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="https://blog.ecoolo.fr" target="_blank" rel="noopener noreferrer" class={`nav-link ${activepg == "details" ? "active" : ""}`} aria-current="page" >
                    Blog
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="/contact" class={`nav-link ${activepg == "contact" ? "active" : ""}`} aria-current="page" onClick={() => setActivePg("contact")}>
                    Nous contacter
                  </Link>
                </li>

              </ul>

            )}


            {data.username ? (
              <div class="dropdown mb-2">
                <button class="btn btn-white dropdown-toggle" type="button" id="dropdownMenuButton12" data-bs-toggle="dropdown" aria-expanded="false">
                  Bonjour {data.username}
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton12">
                  <li>
                    <Link to="/app/user" class="dropdown-item" role="btn">
                      Profil
                    </Link>
                  </li>
                  <li>
                    <a
                      class="dropdown-item "
                      href="#"
                      role="button"
                      onClick={() =>
                        toast((t) => (
                          <span>
                            <b>Déconnexion ? </b>
                            <a
                              className="btn bg-info text-light  mx-2"
                              onClick={() => {
                                logout();
                                toast.dismiss(t.id);
                              }}
                            >
                              <i className="fa fa-check"></i>
                            </a>
                            <a className="btn bg-dark text-light" onClick={() => toast.dismiss(t.id)}>
                              <i className="fa fa-times"></i>
                            </a>
                          </span>
                        ))
                      }
                    >
                      Déconnexion <i className="fa fa-sign-out ms-2" />
                    </a>
                  </li>
                </ul>
              </div>
            ) : (
              < div class="d-lg-flex">
                <Link to="/app/login" class="button-63 fs-6 fw-bold px-3 py-2 btn-outline-primary me-2 w-100 mb-2" type="button">
                  Connexion
                </Link>
                <Link to="/app/signup" class="button-63 fs-6 fw-bold px-3 py-2 btn-primary w-100 mb-2" type="button">
                  S'inscrire
                </Link>
              </div>
            )}
          </div>
        </div>
        <Outlet />
      </nav >
    </>
  );
};

export default Header;
