import React from "react";
import { useState, useEffect } from "react";
import Axios from "axios";
import { useCookies } from "react-cookie";

import { Outlet, Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
const apiUrl = process.env.REACT_APP_BACKEND_URL;
const UpdateUser = (props) => {
  document.title = "Ecoolo - Modifier utilisateur";
  Axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
  Axios.defaults.xsrfCookieName = "csrftoken";

  const [response, setResponse] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(["uToken"]);
  const [next, setNext] = useState(false);
  const [iscar, setCar] = useState(false);

  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = (today.getMonth() + 1).toString().padStart(2, "0"); // Pad single digit months with leading zero

  const [udata, setUdata] = useState(props.data);
  const [isLoading, setIsloading] = useState(false);

  const handlesubmit = (e) => {
    e.preventDefault();
    console.log(udata);

    if (udata.car_motorisation === "ELECTRIC") {
      udata.car_consumption = 0;
      udata.car_fuel = "DIESEL";
    }
    setIsloading(true);
    const config = {
      headers: { "content-type": "application/json" },
    };

    Axios.patch(`${apiUrl}/set-user-informations`, udata, config)
      .then((response) => {
        toast.success("Succès");
        setIsloading(false);
        console.log(response.data);
        setTimeout(() => {
          window.location.href = "/app/user";
        }, 2000);

        // setUdata({ username: "", fname: "", lname: "", email: "", password: "", password2: "" });
      })
      .catch((error) => {
        setResponse(error.response.data.msg);
        toast.error("Une erreur s'est produite ");
        setIsloading(false);
        console.log(error.message);
      });
  };

  return (
    <>
      <Toaster />
      {isLoading && (
        <div className="d-flex align-items-center w-100 justify-content-center" style={{ height: "100lvh", zIndex: "99", position: "fixed", top: "0", left: "0", background: "rgba(255,255,255,0.5)" }}>
          <div className="loader"></div>
        </div>
      )}
      <div className="pattern d-flex align-items-md-center justify-content-center py-1" style={{ height: "100vh", overflow: "auto" }}>
        <div className="row mx-2">
          <form id="uform" onSubmit={handlesubmit} className="col-12 col-xl-8 mx-auto p-lg-4 my-5 text-dark bg-light shadow-lg border-white border-3 border cardrounded">
            <div className="row">
              <div className="col-12 mb-3">
                <div className="d-flex align-items-center justify-content-between">
                  <h4 className="fw-bold py-4">Mettre à jour les informations utilisateur</h4>
                  <button className="btn-dark btn btn-lg rounded-circle" onClick={() => props.isform(false)}>
                    <i className="fa fa-times "></i>
                  </button>
                </div>
                {response !== "" && <p className="fw-bold text-info">{response}</p>}
                <hr />
              </div>

              <div className="col-12 mb-3 col-md-6">
                <label for="co2_emissions_goal" className="form-label">
                  Objectif d'émission de C02 (en tonnes)
                </label>
                <input
                  type="number"
                  className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                  id="co2_emissions_goal"
                  autoComplete="off"
                  value={udata.co2_emissions_goal}
                  onChange={(e) => setUdata({ ...udata, co2_emissions_goal: e.target.value })}
                  style={{ borderRadius: "10px" }}
                />
              </div>

              <div className="col-12 mb-3 col-md-6">
                <label for="people_per_household" className="form-label">
                  Personnes dans le logement
                </label>
                <input
                  type="number"
                  className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                  id="people_per_household"
                  autoComplete="off"
                  value={udata.people_per_household}
                  onChange={(e) => setUdata({ ...udata, people_per_household: e.target.value })}
                  style={{ borderRadius: "10px" }}
                />
              </div>

              <div className="col-12 mb-3 col-md-6">
                <label for="house_size" className="form-label">
                  Superficie du logement
                </label>
                <input
                  type="number"
                  className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                  id="house_size"
                  autoComplete="off"
                  value={udata.house_size}
                  onChange={(e) => setUdata({ ...udata, house_size: e.target.value })}
                  style={{ borderRadius: "10px" }}
                />
              </div>

              <div className="col-12 mb-3 col-md-6 ">
                <label for="year" className="form-label">
                  Année de naissance
                </label>
                <input
                  type="number"
                  className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                  id="year"
                  placeholder="YYYY"
                  autoComplete="off"
                  value={udata.year_of_birth}
                  onChange={(e) => {
                    setUdata({ ...udata, year_of_birth: e.target.value });
                  }}
                  max={`${currentYear} - 3`}
                  style={{ borderRadius: "10px" }}
                />
              </div>

              <div className="col-12 mb-3 col-md-6 ">
                <label for="city" className="form-label">
                  Ville de résidence
                </label>
                <input
                  type="text"
                  className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                  id="city"
                  autoComplete="on"
                  value={udata.city_of_living}
                  onChange={(e) => {
                    setUdata({ ...udata, city_of_living: e.target.value });
                  }}
                  style={{ borderRadius: "10px" }}
                />
              </div>

              <div className="col-12 mb-3 col-md-6 ">
                <label for="city" className="form-label">
                  Habitude alimentaire
                </label>
                <select
                  className="form-control form-select bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                  id="eating_habits"
                  autoComplete="off"
                  value={udata.eating_habits}
                  onChange={(e) => {
                    setUdata({ ...udata, eating_habits: e.target.value });
                  }}
                  style={{ borderRadius: "10px" }}
                >
                  <option selected disabled>
                    Choisissez...
                  </option>
                  <option value="VEGETARIAN">Vegetarien</option>
                  <option value="SMALL_MEAT_CONSUMER">Consommateur de viande léger</option>
                  <option value="REGULAR_MEAT_CONSUMER">Consommateur de viande régulier</option>
                </select>
              </div>
              <hr />
              <div className="col-12 mb-3">
                <div className="row py-2">
                  <div className="col-6">
                    <h4 className="py-2">Possédez-vous une voiture ?</h4>
                  </div>
                  <div className="col-2">
                    <button
                      type="button"
                      onClick={() => {
                        setUdata({ ...udata, has_car: true });
                      }}
                      className={`btn  fw - bold py - 2 btn - lg ${udata.has_car ? "btn-success" : "btn-outline-success"}  me - 3 `}
                    >
                      Oui
                    </button>
                  </div>
                  <div className="col-2">
                    <button
                      type="button"
                      onClick={() => {
                        setUdata({ ...udata, has_car: false });
                      }}
                      className={`btn  fw - bold py - 2 btn - lg ${!udata.has_car ? "btn-danger" : "btn-outline-danger"} `}
                    >
                      Non
                    </button>
                  </div>
                </div>
              </div>

              {udata.has_car && (
                <div className="row">
                  <div className="col-12 mb-3 col-md-6">
                    <label for="car_construction_year" className="form-label">
                      Année de construction de votre voiture
                    </label>
                    <input
                      type="number"
                      className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                      id="car_construction_year"
                      placeholder="YYYY"
                      autoComplete="off"
                      value={udata.car_construction_year}
                      onChange={(e) => {
                        setUdata({ ...udata, car_construction_year: e.target.value });
                      }}
                      max={`${currentYear} `}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>

                  <div className="col-12 mb-3 col-md-6 d-none">
                    <label for="year" className="form-label">
                      Years & Month
                    </label>
                    <input
                      type="month"
                      className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                      id="year"
                      autoComplete="off"
                      value={udata.year + "-" + udata.month}
                      onChange={(e) => {
                        const [year, month] = e.target.value.split("-");
                        setUdata({ ...udata, year: year, month: month });
                      }}
                      max={`${currentYear} -${currentMonth} `}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>



                  <div className="col-12 mb-3 col-md-6">
                    <label for="car_motorisation" className="form-label">
                      Motorisation de la voiture
                    </label>

                    <select
                      className="form-control form-select bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                      id="car_motorisation"
                      autoComplete="off"
                      value={udata.car_motorisation}
                      onChange={(e) => {
                        setUdata({ ...udata, car_motorisation: e.target.value });
                      }}
                      style={{ borderRadius: "10px" }}
                    >
                      <option selected disabled>
                        Select
                      </option>
                      <option value="HYBRID">Hybride</option>

                      <option value="THERMIC">Thermique</option>
                      <option value="ELECTRIC">Electrique</option>
                    </select>
                  </div>

                  {udata.car_motorisation !== "ELECTRIC" && (
                    <div className="row">
                      <div className="col-12 mb-3 col-md-6">
                        <label for="car_consumption" className="form-label">
                          Consommation moyenne de votre voiture (L/100km)
                        </label>
                        <input
                          type="number"
                          className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                          id="car_consumption"
                          autoComplete="off"
                          value={udata.car_consumption}
                          onChange={(e) => setUdata({ ...udata, car_consumption: e.target.value })}
                          style={{ borderRadius: "10px" }}
                        />
                      </div>

                      <div className="col-12 mb-3 col-md-6">
                        <label for="car_fuel" className="form-label">
                          Type de carburant
                        </label>

                        <select
                          className="form-control form-select bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                          id="car_fuel"
                          autoComplete="off"
                          value={udata.car_fuel}
                          onChange={(e) => {
                            setUdata({ ...udata, car_fuel: e.target.value });
                          }}
                          style={{ borderRadius: "10px" }}
                        >
                          <option selected disabled>
                            Select
                          </option>
                          <option value="DIESEL">Diesel</option>

                          <option value="GAZOLINE_E85">Essence E85</option>
                          <option value="GAZOLINE_E5_10">Essence E5 ou E10</option>
                        </select>
                      </div>
                    </div>)}

                  <div className="col-12 mb-3 col-md-6">
                    <label for="car_size" className="form-label">
                      Gabarit de la voiture
                    </label>

                    <select
                      className="form-control form-select bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                      id="car_size"
                      autoComplete="off"
                      value={udata.car_size}
                      onChange={(e) => {
                        setUdata({ ...udata, car_size: e.target.value });
                      }}
                      style={{ borderRadius: "10px" }}
                    >
                      <option selected disabled>
                        Select
                      </option>
                      <option selected>Choisissez...</option>
                      <option value="LIGHT">Petite</option>
                      <option value="MEDIUM">Moyenne</option>
                      <option value="SUV">SUV</option>
                      <option value="SEDAN">Berline</option>
                    </select>
                  </div>

                  <div className="col-12 mb-3 col-md-6">
                    <label for="car_passenger_number" className="form-label">
                      Nombre moyen de voyageurs dans la voiture
                    </label>
                    <input
                      type="number"
                      className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                      id="car_passenger_number"
                      autoComplete="off"
                      value={udata.car_passenger_number}
                      onChange={(e) => setUdata({ ...udata, car_passenger_number: e.target.value })}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                </div>
              )}

              <div className={`col - 12 mb - 3 py - 2 `}>
                <button type="submit" className="button-63 btn-primary text-light">
                  Continuer
                </button>
              </div>
            </div>
          </form>
        </div>

        <Outlet />
      </div>
    </>
  );
};
export default UpdateUser;
