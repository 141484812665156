import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Card from "./cards";
import MonthConsume from "./auth/monthconsume";
import YearDetail from "./yeardetail";
import CookieBanner from "./cookiebanner";
import UpdateYear from "./auth/updateyear";
import Lchart from "./chartdemo";
import Axios from "axios";
import toast from "react-hot-toast";
Axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
Axios.defaults.xsrfCookieName = "csrftoken";
Axios.defaults.withCredentials = true;
const apiUrl = process.env.REACT_APP_BACKEND_URL;
const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"];
const WelcomeModalPage1 = ({ onNext }) => {
  return (
    <div className="modal">
      <div className="col-10 col-lg-8" style={{ height: "100vh" }}>
        <div className="modal-content">
          <h2>Bienvenue sur Ecoolo, votre compagnon pour la transition écologique !</h2>
          <br />
          <div className="">
            <p>Voici quelques instructions pour découvrir l'application:</p>
            <ul className="">
              <li className="ml-20">L'onglet <strong>Consommations</strong> vous permet de visualiser vos consommations passées et d'ajouter les consommations à chaque mois. Elle permet une visualisation mensuelle ou annuelle. <strong>Attention</strong> une consommation ne peut être ajoutée qu'une fois que le mois est fini !</li>
            </ul>
            <ul className="">
              <li className="ml-20">L'onglet <strong>Statistiques</strong> vous permet de visualiser des statistiques sur vos consommations ainsi que celles des utilisateurs de l'application.</li>
            </ul>
            <ul className="">
              <li className="ml-20">L'onglet <strong>Simulation</strong> vous permet d'effectuer une estimation pour une consommation afin d'évaluer son équivalent CO2.</li>
            </ul>
            <ul className="">
              <li className="ml-20">L'onglet <strong>Détails</strong> explique brièvement la méthodologie appliquée pour effectuer nos calculs.</li>
            </ul>
          </div>
          <button className="button-63" onClick={onNext}>Continuer</button>
        </div>
      </div>
    </div>
  );
};

const WelcomeModalPage2 = ({ onClose }) => {
  return (
    <div className="modal">
      <div className="col-md-6 col-8 p-10" style={{ height: "100vh" }}>
        <div className="modal-content p-4">
          <h2>A vous de jouer !</h2>
          <br />
          <p>Afin de calculer votre empreinte carbone, nous avons besoin de stocker vos données. En continuant sur le site, vous consentez au stockage de vos données personnelles liées au calcul d'empreinte carbone.</p>
          <p>Ecoolo est constamment en évolution, n'hésitez pas à nous contacter à <strong>contact@ecoolo.fr</strong> pour nous faire part de vos retours ou nous poser des questions.</p>
          <p>Vous pouvez commencer par ajouter vos consommations des mois passés pour cette année pour commencer à suivre vos actions pour réduire votre empreinte climatique.</p>
          <button className="button-63" onClick={onClose}>Ajouter ma première consommation mensuelle !</button>
        </div>
      </div>
    </div>
  );
};

const Main = (props) => {
  document.title = "Ecoolo - Consommations";
  const [isform, setIsForm] = useState(false);
  const [tab, setTab] = useState("mon");
  const [isLoading, setIsloading] = useState(false);
  const [data, setData] = useState([]);
  const [yeardata, setYearData] = useState([]);
  const [userdata, setUserData] = useState({});
  const [recentMonth, setRecentMonth] = useState([]);
  const [toogle, setToggle] = useState("Année en cours");
  const [response, setResponse] = useState("");
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    // if (cookies.uToken !== undefined) {
    //   window.location.href = "/app/";
    // }
    props.setsc(false);
  }, []);

  //Verify user login
  useEffect(() => {
    setIsloading(true);
    const config = {
      headers: { "content-type": "application/json" },
    };

    Axios.get(`${apiUrl}/user`, {}, config)
      .then((response) => {
        setIsloading(false);
        console.log(response.data);
        setShowWelcomeModal(!response.data.user.has_accepted_tutorial);
        setUserData(response.data.user);
      })
      .catch((error) => {
        console.log(error);
        setIsloading(false);
        window.location.href = "/app/login";
      });
  }, []);

  const handleNextPage = () => {
    setCurrentPage(2);
  };

  const handleCloseModal = () => {
    setIsloading(true);
    const config = {
      headers: { "content-type": "application/json" },
    };
    Axios.get(`${apiUrl}/user-finished-tutorial`, {}, config)
      .then((response) => {
        setIsloading(false);
        setShowWelcomeModal(false);
        setIsForm(true);
      })
      .catch((error) => {
        console.log(error);
        setIsloading(false);
      });
  };
  // Vrrify User Login End========>

  const today = new Date();
  const currentYear = today.getFullYear();

  // Get Users Monthly Footprint =============================>
  useEffect(() => {
    setIsloading(true);
    const config = {
      headers: { "content-type": "application/json" },
    };
    let cyear = toogle == "Année dernière" ? currentYear - 1 : currentYear;
    console.log(cyear);

    setResponse("");
    Axios.post(`${apiUrl}/get-user-monthly-footprints`, { year: cyear }, config)
      .then((response) => {
        setIsloading(false);
        console.log(response);
        response.data.length === 0 ? setResponse("Pas de données pour cette année") : setResponse("");
        setData(response.data.reverse());
      })
      .catch((error) => {
        console.log(error);
        setResponse(error.response.data.msg);
        setIsloading(false);
      });
  }, [toogle]);

  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => {
    if (isChecked) {
      // Action 1 lorsque la checkbox est cochée
      setTab("year");
    } else {
      // Action 2 lorsque la checkbox est décochée
      setTab("mon");
    }
  }, [isChecked]);

  // Get Users Yearly footprints =========================>
  useEffect(() => {
    setIsloading(true);
    const config = {
      headers: { "content-type": "application/json" },
    };

    Axios.get(`${apiUrl}/get-user-yearly-footprints`, {}, config)
      .then((response) => {
        setIsloading(false);
        console.log(response.data);
        // console.log(response.data.filter((e) => e.year == (toogle == "Last Year" ? currentYear - 1 : currentYear)));
        setYearData(response.data.reverse());
      })
      .catch((error) => {
        console.log(error);
        setIsloading(false);
      });
  }, [toogle]);

  // Get User====================>
  useEffect(() => {
    const config = {
      headers: { "content-type": "application/json" },
    };

    Axios.get(`${apiUrl}/user`, {}, config)
      .then((response) => {
        setIsloading(false);
        // console.log(response.data);
        localStorage.setItem("has_car", response.data.user.has_car);
      })
      .catch((error) => {
        console.log(error);
        setIsloading(false);
      });
  }, []);

  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, transform: "scale(1.5)" }}
        onClick={onClick}
      />
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, transform: "scale(1.5)" }}
        onClick={onClick}
      />
    );
  };

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "10px",
    slidesToShow: 3,
    speed: 500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          centerPadding: "25px",
        },
      },
    ],
  };

  return (
    <>
      {isLoading && (
        <div className="d-flex align-items-center w-100 justify-content-center" style={{ height: "100lvh", zIndex: "99", position: "fixed", top: "0", left: "0", background: "rgba(255,255,255,0.5)" }}>
          <div className="loader"></div>
        </div>
      )}

      {showWelcomeModal && (
        currentPage === 1 ? (
          <WelcomeModalPage1 onNext={handleNextPage} />
        ) : (
          <WelcomeModalPage2 onClose={handleCloseModal} />
        )
      )}

      <div className="w-100 bg-light py-5 mx-0 py-0">
        <div className="data_toggles container">
          <div className="row mx-0">
            <div className="col-3 mx-auto text-center">
              <div className="row">
                <div className="col-lg-4 col-6">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={() => setIsChecked(!isChecked)}
                    class="toggle"
                  />
                  <div className="my-auto">
                    {tab == "mon" ? (
                      <span class="fw-bold text-center py-auto mx-2">Mensuel</span>
                    ) : (
                      <span class="fw-bold text-center">Annuel</span>)}
                  </div>
                </div>
              </div>
            </div>
            {tab == "mon" ? (
              <div className="col-9 my-auto text-center">
                <div className="text-end">
                  <div class="dropdown mb-2">
                    <button class="btn btn-primary dropdown-toggle multi-line-btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      {toogle}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <li>
                        <a class="dropdown-item" role="btn" onClick={() => setToggle("Année dernière")} style={{ cursor: "pointer" }}>
                          Année dernière
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item" role="btn" onClick={() => setToggle("Année en cours")} style={{ cursor: "pointer" }}>
                          Année en cours
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>) : (
              <div className="col-9 mx-auto text-center"></div>
            )}
          </div>
        </div>
        {tab == "mon" ? (
          <div className="  d-flex align-items-center justify-content-center py-5 p mx-0">
            <div className="container">
              <div className="container my-4">
                <div className="w-100 text-center ">
                  <div className="d-inline-block ">
                    {tab == "mon" && toogle == "Année en cours" ? (
                      <div className="position-sticky top-5 start-5">
                        <button className="button-63 d-flex align-items-center justify-content-between multi-line-btn" onClick={() => setIsForm(true)}>
                          Ajouter une donnée pour {months[data.length]} <i className="fa fa-plus-circle border-start border-0 border-light fs-3 py-0 my-0 ps-3 fw-bold ms-3"></i>
                        </button>
                      </div>
                    ) : (
                      <button className="btn-primary btn btn-lg d-flex align-items-center justify-content-between d-none" onClick={() => setIsForm(true)}>
                        Mettre à jour les données annuelles <i className="fa fa-plus-circle border-start border-0 border-light fs-3 py-0 my-0 ps-3 fw-bold ms-3"></i>
                      </button>
                    )}
                  </div>
                </div>
              </div>



              {data.length >= 3 && (
                <div className="slider-container mb-5">
                  {response == "" ? (
                    <Slider {...settings}>
                      {data.map((ini, i) => {
                        return <Card data={ini} />;
                      })}
                    </Slider>
                  ) : (
                    <h4 className="display-6 text-center">{response}</h4>
                  )}
                </div>
              )}

              {data.length < 3 && (
                <>
                  {response == "" ? (
                    <div className="row">
                      {data.map((ini, i) => {
                        return (
                          <div className="col-6 col-md-4">
                            <Card data={ini} />
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <h4 className="display-6 text-center">{response}</h4>
                  )}
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="w-100">
            <YearDetail data={data} datayear={yeardata} userinfo={userdata} />
          </div>
        )}

        {tab == "mon" && (
          <div className="container">
            <div className="row mx-0 px-0">
              {data && (
                <div className="col-lg-10  mt-5 mx-auto">
                  <div className="w-100 d-flex align-items-center">
                    <h5 className="display-4 fw-bold text-primary border-secondary border cardrounded px-3 border-1 p-2">{data[0] ? data[0].year : ""}</h5>
                    <hr className="w-100 ms-2 " height="2px" />
                  </div>
                  <div style={{ maxHeight: "500px", height: '500px' }}>
                    <Lchart cType="bar" data={data} />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {isform && tab == "mon" && (
          <div
            className="d-flex position-fixed top-0 left-0 align-items-center justify-content-center"
            style={{ background: "rgba(255,255,255,0.2)", backdropFilter: "blur(5px)", position: "fixed", left: 0, top: 0, zIndex: 9999 }}
          >
            <div className="position-relative">
              <MonthConsume isform={(e) => setIsForm(e)} year={toogle == "Année dernière" ? currentYear - 1 : currentYear} lastMonthAdded={data.length} />
            </div>
          </div>
        )}

        {/* {isform && tab !== "mon" && (
          <div
            className="d-flex position-fixed top-0 left-0 align-items-center justify-content-center"
            style={{ background: "rgba(255,255,255,0.2)", backdropFilter: "blur(5px)", position: "fixed", left: 0, top: 0, zIndex: 9999 }}
          >
            <div className="position-relative">
              <UpdateYear isform={(e) => setIsForm(e)} />
            </div>
          </div>
        )} */}
      </div>
      <CookieBanner />
    </>
  );
};

export default Main;
