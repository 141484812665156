import Main from "./components/main";
import Login from "./components/auth/login";
import SignUp from "./components/auth/signup";
import LastYear from "./components/auth/yearconsum";
import CarInfo from "./components/auth/carinfo";
import Projections from "./components/projections";
import Header from "./components/header";
import Footer from "./components/footer";
import Detail from "./components/detailview";
import Evolutions from "./components/evolution";
import UserDash from "./components/userdash";
import Homepage from "./components/homepage";
import Contact from "./components/contact"
import AppDetails from "./components/appdetails";
import Admin from "./components/admin"
import VerifyEmail from "./components/auth/verify_email";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";


function App() {
  const [isScreen, setScreen] = useState(false);
  useEffect(() => {
    if (window.location.pathname == "/app/login" || window.location.pathname == "/app/signup" || window.location.pathname == "/app/year_consume" || window.location.pathname == "/app/car_info" || window.location.pathname == "/app/verify_email") {
      setScreen(true);
    } else {
      setScreen(false);
    }
  }, []);
  {
    console.log(isScreen);
  }
  return (
    <Router>
      {!isScreen && <Header />}
      <Routes>
        <Route path="/" element={<Homepage setsc={(e) => setScreen(e)} />} />
        <Route path="/app/" element={<Main setsc={(e) => setScreen(e)} />} />
        <Route path="/app/detail" element={<Detail setsc={(e) => setScreen(e)} />} />
        <Route path="/app/login" element={<Login setsc={(e) => setScreen(e)} />} />
        <Route path="/app/signup" element={<SignUp setsc={(e) => setScreen(e)} />} />
        <Route path="/app/verify_email" element={<VerifyEmail setsc={(e) => setScreen(e)} />} />
        <Route path="/app/car_info" element={<CarInfo setsc={(e) => setScreen(e)} />} />
        <Route path="/app/year_consume" element={<LastYear setsc={(e) => setScreen(e)} />} />
        <Route path="/app/projection" element={<Projections setsc={(e) => setScreen(e)} />} />
        <Route path="/app/evolutions" element={<Evolutions setsc={(e) => setScreen(e)} />} />
        <Route path="/app/admin" element={<Admin setsc={(e) => setScreen(e)} />} />
        <Route path="/app/user" element={<UserDash setsc={(e) => setScreen(e)} />} />
        <Route path="/contact" element={<Contact setsc={(e) => setScreen(e)} />} />
        <Route path="/details" element={<AppDetails setsc={(e) => setScreen(e)} />} />
        <Route path="*" element={<Homepage setsc={(e) => setScreen(e)} />} />
      </Routes>
      {!isScreen && <Footer />}
    </Router>
  );
}

export default App;
