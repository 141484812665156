import React from "react";
import { useState, useEffect } from "react";
import Axios from "axios";
import { useCookies } from "react-cookie";

import { Outlet, Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
const apiUrl = process.env.REACT_APP_BACKEND_URL;
Axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
Axios.defaults.xsrfCookieName = "csrftoken";
Axios.defaults.withCredentials = true;

const SignUp = (props) => {
  document.title = "Ecoolo - Sign Up";

  const [response, setResponse] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(["uToken"]);
  const [next, setNext] = useState(false);
  const [iscar, setCar] = useState(false);

  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = (today.getMonth() + 1).toString().padStart(2, "0"); // Pad single digit months with leading zero

  const [udata, setUdata] = useState({
    username: "",
    email: "",
    password: "",
    password2: "",
    year_of_birth: null,
    city_of_living: "",
  });
  const [isLoading, setIsloading] = useState(false);

  const isDataValid = () => {
    for (const key in udata) {
      if (udata.hasOwnProperty(key) && (udata[key] === "" || udata[key] === null)) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    props.setsc(true);
  }, []);

  const handlesubmit = (e) => {
    e.preventDefault();
    console.log(udata);

    if (isDataValid()) {
      setIsloading(true);
      const config = {
        headers: { "content-type": "application/json" },
      };

      Axios.post(`${apiUrl}/register`, udata, config)
        .then((response) => {


          toast.success("Succès");
          setIsloading(false);
          window.location.href = "/app/verify_email";

          setUdata({ username: "", fname: "", lname: "", email: "", password: "", password2: "" });
        })
        .catch((error) => {
          setResponse(error.response.data.msg);
          toast.error(error.response.data.msg);
          setIsloading(false);
          console.log(error.response);
        });
    } else {
      toast.error("Form is incomplete");
    }
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  return (
    <>
      <Toaster />
      {isLoading && (
        <div className="d-flex align-items-center w-100 justify-content-center" style={{ height: "100lvh", zIndex: "99", position: "fixed", top: "0", left: "0", background: "rgba(255,255,255,0.5)" }}>
          <div className="loader"></div>
        </div>
      )}
      <div className="pattern d-flex align-items-center justify-content-center min-vh-100">
        <form id="uform" onSubmit={handlesubmit} className="row col-11 col-xl-8 col-lg-5  p-4 my-5 text-dark bg-light shadow-lg border-white border-3 border cardrounded" data-aos="fade-down">
          {!next && (
            <div className="row">
              <div className="col-12 mb-3">
                <h3 className="fw-bold py-4">Créer un compte sur Ecoolo, c'est simple et rapide, on commence ?</h3>
                {response !== "" && <p className="fw-bold text-info">{response}</p>}
                <hr />
              </div>
              <div className="col-12 mb-3">
                <label for="username" className="form-label">
                  Quel est votre nom d'utilisateur ?
                </label>
                <input
                  required
                  type="text"
                  className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                  id="username"
                  placeholder="john123"
                  autoComplete="off"
                  value={udata.username}
                  onChange={(e) => setUdata({ ...udata, username: e.target.value })}
                  style={{ borderRadius: "10px" }}
                />
              </div>
              <div className="col-12 mb-3">
                <label for="Email" className="form-label">
                  Email
                </label>
                <input
                  required
                  type="email"
                  className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                  id="Email"
                  autoComplete="email"
                  value={udata.email}
                  onChange={(e) => setUdata({ ...udata, email: e.target.value })}
                  style={{ borderRadius: "10px" }}
                />
              </div>

              {!isValidEmail(udata.email) && (udata.email !== "") && <span className="d-block px-2  py-1 my-2 rounded bg-warning text-dark text-lead">Email non valide</span>}

              <div className="col-12 mb-3 ">
                <label for="Password" className="form-label">
                  Choisir un mot de passe
                </label>
                <input
                  required
                  type="password"
                  className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                  id="Password"
                  autoComplete="new-password"
                  value={udata.password}
                  onChange={(e) => setUdata({ ...udata, password: e.target.value })}
                  style={{ borderRadius: "10px" }}
                />
              </div>
              <div className="col-12 mb-3">
                <label for="Password" className="form-label">
                  Confirmer le mot de passe
                </label>
                <input
                  required
                  type="password"
                  className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                  id="Password"
                  autoComplete="new-password"
                  value={udata.password2}
                  onChange={(e) => setUdata({ ...udata, password2: e.target.value })}
                  style={{ borderRadius: "10px" }}
                />
              </div>

              <div className="col-12 mb-3 col-md-6 ">
                <label for="year" className="form-label">
                  Année de naissance
                </label>
                <input
                  required
                  type="number"
                  className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                  id="year"
                  autoComplete="off"
                  value={udata.year_of_birth}
                  onChange={(e) => {
                    setUdata({ ...udata, year_of_birth: e.target.value });
                  }}
                  max={`${currentYear}-3`}
                  style={{ borderRadius: "10px" }}
                />
              </div>

              <div className="col-12 mb-3 col-md-6 ">
                <label for="city" className="form-label">
                  Ville de résidence
                </label>
                <input
                  required
                  type="text"
                  className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                  id="city"
                  autoComplete="on"
                  value={udata.city_of_living}
                  onChange={(e) => {
                    setUdata({ ...udata, city_of_living: e.target.value });
                  }}
                  style={{ borderRadius: "10px" }}
                />
              </div>

              {udata.password !== udata.password2 && <span className="d-block px-2  py-1 my-2 rounded bg-warning text-dark text-lead">Password and Confirm - Password should Match</span>}

              <div className={`col-12 mb-3 py-2 `}>
                <button type="submit" className="btn w-100 fw-bold py-2 btn-lg btn-dark text-light">
                  Suivant <i className="fa fa-arrow-circle-right ms-3"></i>
                </button>
              </div>
            </div>
          )}

          <p className="lead">
            Vous avez déjà un compte ?
            <Link to="/app/login" state={{ check: "Login" }} className="link-info text-decoration-none fw-bold">
              {" "}
              Connexion
            </Link>
          </p>
        </form>

        <Outlet />
      </div>
    </>
  );
};
export default SignUp;
