import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import "../styles/_master.scss"

const Homepage = (props) => {
    document.title = "Ecoolo - L'application pour suivre son empreinte carbone";
    useEffect(() => {
        // if (cookies.uToken !== undefined) {
        //   window.location.href = "/app/";
        // }
        props.setsc(false);
    }, []);

    return (
        <div>
            <div className="col-10 col-sm-8 mx-auto mx-8 p-8 my-4">
                <div className="bg-primary-opacity shadow cardrounded">
                    <div className="row">
                        <div className="col-lg-11 mx-auto">
                            <h1 className="fw-bold py-4 text-center">Ecoolo - L'application pour suivre son empreinte carbone</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="m-4">
                <div className="container-fluid m-4">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="bg-primary-opacity shadow p-4 cardrounded">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2 className="fw-bold py-4 text-center">Avec Ecoolo, agissons pour réduire nos émissions de CO2</h2>
                                        <div className="row">
                                            <div className="col-lg-4 text-center my-auto">
                                                <p> Ecoolo est une application entièrement gratuite qui permet de mesurer ses <strong>émissions mensuelles</strong> de carbone liées à tes consommations afin de te permettre de réduire ton <strong>empreinte carbone.</strong></p>
                                                <div className="row py-4">
                                                    <Link to="/app/signup" class="button-63 col-8 mx-auto fs-15 fw-bold px-3 py-2 btn-primary mb-2" type="button">
                                                        On essaie ?
                                                    </Link>
                                                </div>

                                            </div>
                                            <div className="col-lg-8">
                                                <img src="./images/month_evolution.png" className="cardrounded img-fluid p-4" alt="Evolution de l'empreinte mensuelle" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid m-4">
                    <div className="row justify-content-end">
                        <div className="col-lg-8 p-2">
                            <div className="bg-primary-opacity shadow p-4 cardrounded" >
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2 className="fw-bold py-4 text-center">L'application Ecoolo en quelques mots</h2>
                                        <div className="row">
                                            <div className="col-lg-4 text-center my-auto">
                                                <p>L'application Ecoolo permet un <strong>suivi mensuel des émissions de CO2</strong>. Les utilisateurs peuvent ainsi évaluer leurs habitudes de consommation en termes d'émissions de CO2. Grâce à cette application intuitive, les utilisateurs peuvent saisir facilement leurs données de consommation mensuelle dans divers domaines tels que <strong>l'énergie domestique, les déplacements, d'autres sources potentielles d'émissions de carbone</strong>. Une fois les données saisies, l'application utilise des algorithmes pour calculer de manière précise les émissions de CO2 correspondantes, offrant ainsi aux utilisateurs une estimation claire de leur empreinte carbone mensuelle</p>
                                            </div>
                                            <div className="col-lg-8 col-12">
                                                <img src="./images/month_consumption.png" className="cardrounded img-fluid p-4" alt="Evolution de l'empreinte mensuelle" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid m-4">
                    <div className="row">
                        <div className="col-lg-8 p-2">
                            <div className="bg-primary-opacity shadow p-4 cardrounded" >
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h2 className="fw-bold py-4 text-center">Découvre tes statistiques et celles des autres utilisateurs</h2>
                                        <div className="row">
                                            <div className="col-lg-4 text-center my-auto">
                                                <p>L'application Ecoolo permet d'analyser tes <strong>principaux postes d'émissions</strong> et de te comparer aux autres utilisateurs pour te motiver à réduire ton empreinte carbone.</p>
                                            </div>
                                            <div className="col-lg-8 col-12">
                                                <img src="./images/statistics.png" className="cardrounded img-fluid p-4" alt="Evolution de l'empreinte mensuelle" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid m-4">
                    <div className="row justify-content-end">
                        <div className="col-lg-8 p-2">
                            <div className="bg-primary-opacity shadow p-4 cardrounded">
                                <div className="row">
                                    <div className="col-12">
                                        <h2 className="fw-bold py-4 text-center">Où en sommes nous dans les émissions de carbone</h2>
                                        <div className="row">
                                            <div className="text-center my-auto">
                                                <p>Selon les rapports du <strong>Groupe d'experts intergouvernemental sur l'évolution du climat (GIEC)</strong>, les émissions de CO2 par habitant sont encore à des niveaux trop élevés. En France, la moyenne est de <strong>9,2 tonnes de CO2 par habitant et par an</strong>, pour respecter les accords de Paris et limiter le réchauffement climatique à 1,5° il faudra atteindre les 2 tonnes de CO2.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Homepage;