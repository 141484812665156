import { React, useState, useEffect, useRef } from "react";
import { Doughnut } from "react-chartjs-2";

import {
  ComposedChart,
  Line,
  Label,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Pie,
  PieChart,
  Cell,
  linearGradient,
} from 'recharts';
import { Chart as ChartJS } from "chart.js/auto";
import colors from "../styles/_master.scss";

const Lchart = (props) => {
  const [options, setOptions] = useState({
    plugins: {
      legend: {
        display: true,
        position: "right",
      },
    },
  });

  const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"];


  useEffect(() => {
    const updateTotalNumbers = () => {
      // You can adjust the logic based on your requirements
      const screenWidth = window.innerWidth;
      let updatedTotalNumbers;

      // Add your own logic to determine the totalNumbers based on screenWidth
      // For example, you can set different totalNumbers for different screen sizes
      if (screenWidth >= 1200) {
        setOptions({
          plugins: {
            legend: {
              display: true,
              position: "right",
            },
          },
        });
      } else if (screenWidth >= 768) {
        setOptions({
          plugins: {
            legend: {
              display: true,
              position: "bottom",
            },
          },
        });
      } else {
        setOptions({
          plugins: {
            legend: {
              display: true,
              position: "bottom",
            },
          },
        });
      }
    };

    // Initial update
    updateTotalNumbers();

    // Event listener for window resize
    window.addEventListener("resize", updateTotalNumbers);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateTotalNumbers);
    };
  }, []); // Empty dependency array means this effect will run once on mount

  ChartJS.defaults.color = "rgba(0,0,0,0.8)";
  const gradientColor = "linear-gradient(to top, #0066ff, #00cc66)";
  const RoundedBarShape = (props) => {
    const { x, y, width, height, fill } = props;
    const radius = 10; // ajustez le rayon selon vos besoins
    const shadowId = `shadow-${Math.random().toString(36).substr(2, 5)}`; // Générer un ID unique pour l'ombre
    return (
      <g>
        <defs>
          <filter id={shadowId} x="-20%" y="-20%" width="140%" height="140%">
            <feDropShadow dx="3" dy="3" stdDeviation="3" floodColor="#8884d8" floodOpacity="0.8" />
          </filter>
        </defs>
        <path d={`M${x},${y + height} 
                  L${x},${y + radius} 
                  Q${x},${y} ${x + radius},${y} 
                  L${x + width - radius},${y} 
                  Q${x + width},${y} ${x + width},${y + radius} 
                  L${x + width},${y + height} Z`}
          fill={fill}
          filter={`url(#${shadowId})`} // Appliquer l'ombre
        />
      </g>
    );
  };


  if (props.data && props.cType == "bar") {
    let dataArray = [];
    for (let i = 0; i < props.data.length; i++) {
      dataArray.push({
        month: months[props.data[i].month - 1],
        footprint: props.data[i].monthly_footprint.toFixed(0),
      });
    }
    const maxValue = Math.ceil(Math.max(...dataArray.map(entry => entry.footprint)) / 100) * 100;

    return <>{props.cType == "bar" && <ResponsiveContainer className="chart-container" width="100%" height="100%">
      <ComposedChart
        width={500}
        height={400}
        data={dataArray}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >

        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="month" scale="band" />
        <YAxis domain={[0, maxValue]} />
        <Tooltip />
        <Legend />
        <defs>
          <linearGradient id={`colorUv`} x1="1" y1="1" x2="0" y2="0">
            <stop offset="30%" stopColor="#00DDEB" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#AF40FF" stopOpacity={1} />
          </linearGradient>
        </defs>
        <Bar dataKey="footprint" name="Empreinte mensuelle (kgCO2)" barSize={25} fill="url(#colorUv)" radius={4} animationEasing="linear" shape={<RoundedBarShape />} />
      </ComposedChart>
    </ResponsiveContainer>}</>;
  }
  if (props.data && props.cType == "barYear") {
    let dataArray = [];
    for (let i = 0; i < props.data.length; i++) {
      dataArray.push({
        year: props.data[i].year,
        footprint: props.data[i].yearly_footprint.toFixed(0),
        energy_footprint: props.data[i].energy_footprint.toFixed(0),
        transport_footprint: props.data[i].transport_footprint.toFixed(0),
        other_footprint: props.data[i].other_footprint.toFixed(0),
      });
    }
    const maxValue = Math.ceil(Math.max(...dataArray.map(entry => entry.footprint)) / 100) * 100;
    return <>{props.cType == "barYear" && <ResponsiveContainer className="chart-container" width="100%" height="100%">
      <ComposedChart
        width={500}
        height={400}
        data={dataArray}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="year" scale="band" position="bottom" textAnchor="middle" />
        <YAxis domain={[0, maxValue]} />
        <Tooltip />
        <Legend />
        <defs>
          <linearGradient id={`colorTotal`} x1="1" y1="1" x2="0" y2="0">
            <stop offset="30%" stopColor={colors.color_total} stopOpacity={0.2} />
            <stop offset="95%" stopColor={colors.color_total} stopOpacity={1} />
          </linearGradient>
          <linearGradient id={`colorEnergy`} x1="1" y1="1" x2="0" y2="0">
            <stop offset="30%" stopColor={colors.color_energy} stopOpacity={0.2} />
            <stop offset="95%" stopColor={colors.color_energy} stopOpacity={1} />
          </linearGradient>
          <linearGradient id={`colorTransport`} x1="1" y1="1" x2="0" y2="0">
            <stop offset="30%" stopColor={colors.color_transport} stopOpacity={0.2} />
            <stop offset="95%" stopColor={colors.color_transport} stopOpacity={1} />
          </linearGradient>
          <linearGradient id={`colorOther`} x1="1" y1="1" x2="0" y2="0">
            <stop offset="30%" stopColor={colors.color_other} stopOpacity={0.2} />
            <stop offset="95%" stopColor={colors.color_other} stopOpacity={1} />
          </linearGradient>
        </defs>
        <Bar dataKey="footprint" name="Empreinte totale (kgCO2)" barSize={25} fill="url(#colorTotal)" radius={4} animationEasing="linear" shape={<RoundedBarShape />} />
        <Bar dataKey="energy_footprint" name="Empreinte liée au logement (kgCO2)" barSize={25} fill="url(#colorEnergy)" radius={4} animationEasing="linear" shape={<RoundedBarShape />} />
        <Bar dataKey="transport_footprint" name="Empreinte liée aux transports (kgCO2)" barSize={25} fill="url(#colorTransport)" radius={4} animationEasing="linear" shape={<RoundedBarShape />} />
        <Bar dataKey="other_footprint" name="Autre (kgCO2)" barSize={25} fill="url(#colorOther)" radius={4} animationEasing="linear" shape={<RoundedBarShape />} />
      </ComposedChart>
    </ResponsiveContainer>}</>;
  } else {
    const COLORS = [colors.color_energy, colors.color_transport, colors.color_other];

    let dataArray = [];
    dataArray.push({ name: 'Logement', value: props.datachart.energy_footprint });
    dataArray.push({ name: 'Transport', value: props.datachart.transport_footprint });
    dataArray.push({ name: 'Autre', value: props.datachart.other_footprint });
    console.log(dataArray)

    const calculateOuterRadius = () => {
      // Vous pouvez ajuster ce coefficient selon vos besoins
      return Math.min(window.innerWidth, window.innerHeight) * 0.1;
    };

    return <>{props.cType == "dough" &&
      <ResponsiveContainer className="chart-container" width="100%" height="100%" ><PieChart>
        <Pie
          data={dataArray}
          cx="50%"
          cy="50%"
          innerRadius={`${calculateOuterRadius() - calculateOuterRadius() * 0.4}%`}
          outerRadius={`${calculateOuterRadius()}%`}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
        >
          {dataArray.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}

        </Pie>
        <Legend />
        <Tooltip />
      </PieChart></ResponsiveContainer>}</>;
  }
};

export default Lchart;
