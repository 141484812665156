import React from "react";
import { FaFacebookSquare } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";

const Footer = () => {
  return (
    <div className="w-100 bg-light border-1 border-top border-muted ">
      <div className="container mt-1 pt-1">
        <footer class="py-3 mt-1">
          <ul class="nav justify-content-center pb-1 mb-3">
            <li class="nav-item">
              <a href="/" class="nav-link px-2 text-muted">
                Home
              </a>
            </li>
            <li class="nav-item">
              <a href="/contact" class="nav-link px-2 text-muted">
                Nous contacter
              </a>
            </li>
          </ul>
          <ul class="nav justify-content-center pb-3 mb-3">
            {/*<li>
              <a href="https://www.facebook.com/profile.php?id=61558067292790" target="_blank" rel="noopener noreferrer">
                <FaFacebookSquare size={35} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'gray', marginRight: '10px' }} />
              </a>
            </li>
            */}
            <br></br>
            <li>
              <a href="https://www.twitter.com/EcooloApp" target="_blank" rel="noopener noreferrer">
                <FaSquareXTwitter size={35} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'gray', marginRight: '10px' }} />
              </a>
            </li>
          </ul>
          <p class="text-center text-muted">© 2024 Ecoolo, Inc</p>

        </footer>
      </div>
    </div>
  );
};

export default Footer;
