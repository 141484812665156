import React from "react";
import { useState, useEffect } from "react";
import Axios from "axios";
import { useCookies } from "react-cookie";
import { Outlet, Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
const apiUrl = process.env.REACT_APP_BACKEND_URL;
Axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
Axios.defaults.xsrfCookieName = "csrftoken";
Axios.defaults.withCredentials = true;
const CarInfo = (props) => {
  document.title = "Ecoolo - Sign Up";

  const [response, setResponse] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(["uToken"]);
  const [next, setNext] = useState(false);
  const [iscar, setCar] = useState(false);

  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = (today.getMonth() + 1).toString().padStart(2, "0"); // Pad single digit months with leading zero

  const [udata, setUdata] = useState({
    has_car: false,

    co2_emissions_goal: 8,
    energy_footprint_goal: 0,
    transport_footprint_goal: 0,
    year: currentYear - 1,
    people_per_household: 1,
    month: 12,

    car_construction_year: null,
    car_fuel: null,
    car_size: null,
    car_motorisation: null,
    car_consumption: null,
    car_passenger_number: 1,
  });
  const [isLoading, setIsloading] = useState(false);

  const isDataValid = () => {
    for (const key in udata) {
      if (udata.hasOwnProperty(key) && udata[key] === "") {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    // if (cookies.uToken !== undefined) {
    //   window.location.href = "/app/";
    // }
    props.setsc(true);
    localStorage.setItem("has_car", "false");
  }, []);

  const handlesubmit = (e) => {
    e.preventDefault();
    console.log(udata);
    if (udata.car_motorisation === "ELECTRIC") {
      udata.car_consumption = 0;
      udata.car_fuel = "DIESEL";
    }
    if (isDataValid()) {
      setIsloading(true);
      const config = {
        headers: { "content-type": "application/json" },
      };

      Axios.patch(`${apiUrl}/set-user-informations`, udata, config)
        .then((response) => {
          // setResponse(response.data);

          toast.success("Succès");
          setIsloading(false);
          console.log(response.data);
          window.location.href = "/app/year_consume";
        })
        .catch((error) => {
          setResponse(error.response.data.msg);
          toast.error("Une erreur s'est produite ");
          setIsloading(false);
          console.log(error);
        });
    } else {
      toast.error("Veuillez renseigner tous les champs du formulaire");
    }
  };

  return (
    <>
      <Toaster />
      {isLoading && (
        <div className="d-flex align-items-center w-100 justify-content-center" style={{ height: "100lvh", zIndex: "99", position: "fixed", top: "0", left: "0", background: "rgba(255,255,255,0.5)" }}>
          <div className="loader"></div>
        </div>
      )}
      <div className="pattern d-flex align-items-center justify-content-center min-vh-100" >
        <form id="uform" onSubmit={handlesubmit} className="row col-11 col-xl-8 col-lg-5 p-4 my-5 text-dark bg-light shadow-lg border-white border-3 border cardrounded" data-aos="fade-down">
          <div className="row">
            <div className={`col-12 mb-3 py-2 text-start`}>
              <h3 className="fw-bold py-4">Maintenant, il nous faut quelques informations pour faire le calcul de vos estimations</h3>
              {response !== "" && <p className="fw-bold text-info">{response}</p>}
              <hr />
            </div>
            <div className="col-12 mb-3 col-md-6">
              <label for="co2_emissions_goal" className="form-label">
                Quel est votre objectif en terme d'émission de C02 (en tonnes) ?
              </label>
              <input
                required
                type="number"
                className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                id="co2_emissions_goal"
                placeholder="8"
                autoComplete="off"
                value={udata.co2_emissions_goal}
                onChange={(e) => setUdata({ ...udata, co2_emissions_goal: e.target.value })}
                style={{ borderRadius: "10px" }}
              />
            </div>

            <div className="col-12 mb-3 col-md-6">
              <label for="people_per_household" className="form-label">
                Combien de personnes vivent dans votre logement ?
              </label>
              <input
                required
                type="number"
                className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                id="people_per_household"
                placeholder="1"
                autoComplete="off"
                value={udata.people_per_household}
                min={1}
                onChange={(e) => setUdata({ ...udata, people_per_household: e.target.value })}
                style={{ borderRadius: "10px" }}
              />
            </div>

            <div className="col-12 mb-3 col-md-6">
              <label for="house_size" className="form-label">
                Quelle est la superficie de votre logement (en m²) ?
              </label>
              <input
                type="number"
                className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                id="house_size"
                autoComplete="off"
                min={1}
                value={udata.house_size}
                onChange={(e) => setUdata({ ...udata, house_size: e.target.value })}
                style={{ borderRadius: "10px" }}
              />
            </div>

            <div className="col-12 mb-3 col-md-6 ">
              <label for="eating_habits" className="form-label">
                Quelle est votre habitude alimentaire ?
              </label>
              <select
                className="form-control form-select bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                id="eating_habits"
                autoComplete="off"
                value={udata.eating_habits}
                onChange={(e) => {
                  setUdata({ ...udata, eating_habits: e.target.value });
                }}
                style={{ borderRadius: "10px" }}
              >
                <option selected disabled>
                  Choisissez...
                </option>
                <option value="VEGETARIAN">Vegetarien</option>
                <option value="SMALL_MEAT_CONSUMER">Consommateur de viande léger</option>
                <option value="REGULAR_MEAT_CONSUMER">Consommateur de viande régulier</option>
              </select>
            </div>
            <div className="row align-items-center">
              <div className="col-12 col-md-6 mb-3">
                <h4 className="py-4">Est-ce que vous possédez une voiture ?</h4>
              </div>
              <div className="col-12 col-md-6 d-flex flex-md-row mb-3">
                <button
                  type="button"
                  onClick={() => {
                    localStorage.setItem("has_car", "true");
                    setUdata({ ...udata, has_car: true, car_construction_year: 0, car_fuel: "", car_size: "", car_motorisation: "", car_consumption: "", car_passenger_number: 0 });
                  }}
                  className={`btn fw-bold py-2 btn-lg ${udata.has_car ? "btn-success" : "btn-outline-success"} me-3`}
                >
                  Oui <i className="fa ms-2 "></i>
                </button>
                <button
                  type="button"
                  onClick={() => {
                    localStorage.setItem("has_car", "false");
                    setUdata({ ...udata, has_car: false, car_construction_year: 0, car_fuel: null, car_size: null, car_motorisation: null, car_consumption: null, car_passenger_number: 0 });
                  }}
                  className={`btn fw-bold py-2 btn-lg ${!udata.has_car ? "btn-danger" : "btn-outline-danger"}`}
                >
                  Non <i className="fa ms-2"></i>
                </button>
              </div>
            </div>
          </div>

          <div className="row">
            {udata.has_car && (
              <div className="row">
                <div className="col-12 mb-3 col-md-6">
                  <label for="car_construction_year" className="form-label">
                    Année de construction de votre voiture:
                  </label>
                  <input
                    type="number"
                    className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                    id="car_construction_year"
                    placeholder="YYYY"
                    autoComplete="off"
                    value={udata.car_construction_year}
                    onChange={(e) => {
                      setUdata({ ...udata, car_construction_year: e.target.value });
                    }}
                    max={`${currentYear}`}
                    style={{ borderRadius: "10px" }}
                  />
                </div>

                <div className="col-12 mb-3 col-md-6 d-none">
                  <label for="year" className="form-label">
                    Years & Month
                  </label>
                  <input
                    type="month"
                    className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                    id="year"
                    placeholder="500"
                    autoComplete="off"
                    value={udata.year + "-" + udata.month}
                    onChange={(e) => {
                      const [year, month] = e.target.value.split("-");
                      setUdata({ ...udata, year: year, month: month });
                    }}
                    max={`${currentYear}-${currentMonth}`}
                    style={{ borderRadius: "10px" }}
                  />
                </div>

                <div className="col-12 mb-3 col-md-6">
                  <label for="car_motorisation" className="form-label">
                    Quelle est la motorisation de votre voiture ?
                  </label>

                  <select
                    className="form-control form-select bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                    id="car_motorisation"
                    autoComplete="off"
                    value={udata.car_motorisation}
                    onChange={(e) => {
                      setUdata({ ...udata, car_motorisation: e.target.value });
                    }}
                    style={{ borderRadius: "10px" }}
                  >
                    <option selected>Choisissez...</option>
                    <option value="HYBRID">Hybride</option>

                    <option value="THERMIC">Thermique</option>
                    <option value="ELECTRIC">Electrique</option>
                  </select>
                </div>

                {udata.car_motorisation !== "ELECTRIC" && (
                  <div className="row">
                    <div className="col-12 mb-3 col-md-6">
                      <label for="car_fuel" className="form-label">
                        Quel est le type de carburant de votre voiture ?
                      </label>

                      <select
                        className="form-control form-select bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                        id="car_fuel"
                        placeholder="500"
                        autoComplete="off"
                        value={udata.car_fuel}
                        onChange={(e) => {
                          setUdata({ ...udata, car_fuel: e.target.value });
                        }}
                        style={{ borderRadius: "10px" }}
                      >
                        <option selected>Choisissez...</option>
                        <option value="DIESEL">Diesel</option>

                        <option value="GAZOLINE_E85">Essence E85</option>
                        <option value="GAZOLINE_E5_10">Essence E5 ou E10</option>
                      </select>
                    </div>

                    <div className="col-12 mb-3 col-md-6">
                      <label for="car_consumption" className="form-label">
                        Consommation moyenne de votre voiture (L/100km):
                      </label>
                      <input
                        type="number"
                        className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                        id="car_consumption"
                        autoComplete="off"
                        value={udata.car_consumption}
                        min={1}
                        max={12}
                        onChange={(e) => setUdata({ ...udata, car_consumption: e.target.value })}
                        style={{ borderRadius: "10px" }}
                      />
                    </div>
                  </div>)}

                <div className="col-12 mb-3 col-md-6">
                  <label for="car_size" className="form-label">
                    Quel est le gabarit de votre voiture ?
                  </label>

                  <select
                    className="form-control form-select bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                    id="car_size"
                    placeholder="500"
                    autoComplete="off"
                    value={udata.car_size}
                    onChange={(e) => {
                      setUdata({ ...udata, car_size: e.target.value });
                    }}
                    style={{ borderRadius: "10px" }}
                  >
                    <option selected>Choisissez...</option>
                    <option value="LIGHT">Petite</option>
                    <option value="MEDIUM">Moyenne</option>
                    <option value="SUV">SUV</option>
                    <option value="SEDAN">Berline</option>
                  </select>
                </div>



                <div className="col-12 mb-3 col-md-6">
                  <label for="car_passenger_number" className="form-label">
                    Nombre de voyageurs dans la voiture en moyenne:
                  </label>
                  <input
                    type="number"
                    className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                    id="car_passenger_number"
                    placeholder="1"
                    autoComplete="off"
                    min={1}
                    value={udata.car_passenger_number}
                    onChange={(e) => setUdata({ ...udata, car_passenger_number: e.target.value })}
                    style={{ borderRadius: "10px" }}
                  />
                </div>
              </div>
            )}
            <div className={`col-12 mb-3 py-2 `}>
              <button type="submit" className="button-63 btn-primary text-light">
                Continuer
              </button>
            </div>
          </div>

        </form >

        <Outlet />
      </div >
    </>
  );
};
export default CarInfo;
