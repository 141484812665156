import React, { useEffect, useState } from "react";
import Axios from "axios";
import Lchart from "./chartdemo";
Axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
Axios.defaults.xsrfCookieName = "csrftoken";
Axios.defaults.withCredentials = true;
const apiUrl = process.env.REACT_APP_BACKEND_URL;
const YearDetail = (props) => {
  //   useEffect(() => {
  //     props.setsc(false);
  //   }, []);
  const [data, setData] = useState(0);
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    const config = {
      headers: { "content-type": "application/json" },
    };

    Axios.get(`${apiUrl}/compare-user-footprint`, {}, config)
      .then((response) => {
        // console.log(response);
        const dbValues = {};
        const userValues = {};

        Object.keys(response.data).forEach((key) => {
          if (key.startsWith("user_")) {
            setData(response.data['user_footprint_mean']);
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="p-lg-3 p-1">

      <div className="shadow cardrounded py-5">
        <div className="container">
          <div className="row pb-4 pt-2 mx-5">
            <div className="w-100 d-flex align-items-center">
              {/* <h5 className="display-4 fw-bold text-primary border-secondary border cardrounded px-3 border-1 p-2">{props.datayear.year}</h5> */}
              <hr className="w-100 ms-2 " height="2px" />
            </div>
            <div style={{ maxHeight: "700px", height: '500px' }}>
              <Lchart cType="barYear" data={props.datayear} />
            </div>

            <div className="row mx-auto">
              <div className="col-md-6 col-12 mt-3" >
                <div className="d-flex align-items-center justify-content-between">
                  <div className="text-center bg-dark-opacity cardrounded pt-4 px-3 w-100 me-3 border-primary border border-1 shadow-sm">
                    <span className="bg-white text-primary p-3 rounded-circle text-white fs-4 my-2">
                      <i className="fa fa-leaf"></i>
                    </span>
                    <h5 className="fw-bold fs-3 mb-0 pb-0 mt-3 text-primary"> {props.userinfo.co2_emissions_goal}T C02</h5>
                    <p class="text-lead fw-bold  text-muted ">Objectif fixé</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-12 mx-auto mt-3">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="text-center bg-dark-opacity cardrounded pt-4 px-3 w-100 me-3 border-primary border border-1 shadow-sm">
                    <span className="bg-white text-primary p-3 rounded-circle text-white fs-4 my-2">
                      <i className="fa fa-leaf"></i>
                    </span>
                    <h5 className="fw-bold fs-3 mb-0 pb-0 mt-3 text-primary"> {(data / 1000).toFixed(1)}T CO2</h5>
                    <p class="text-lead fw-bold  text-muted ">Votre moyenne annuelle estimée</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YearDetail;
