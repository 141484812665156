import React, { useEffect, useState } from "react";
import Lchart from "./chartdemo";
import UpdateUser from "./auth/updateuser";
import { constants_enum } from "../utils/constants.js";
import Axios from "axios";
const apiUrl = process.env.REACT_APP_BACKEND_URL;
Axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
Axios.defaults.xsrfCookieName = "csrftoken";
Axios.defaults.withCredentials = true;

const UserDash = (props) => {
  document.title = "Ecoolo - Utilisateur";
  const [isLoading, setIsloading] = useState(false);
  const [data, setData] = useState([]);
  const [dataFromated, setDataFormated] = useState([]);
  const [isform, setIsForm] = useState(false);
  useEffect(() => {
    props.setsc(false);
  }, []);

  const renderParam = (inputDict) => {
    const outputDict = {};
    for (const key in inputDict) {
      switch (key) {
        case 'car_motorisation':
          console.log(constants_enum[key][inputDict[key]]);
          outputDict[key] = constants_enum[key][inputDict[key]];
          break;
        case 'car_fuel':
          outputDict[key] = constants_enum[key][inputDict[key]];
          break;
        case 'car_size':
          outputDict[key] = constants_enum[key][inputDict[key]];
          break;
        case 'eating_habits':
          outputDict[key] = constants_enum[key][inputDict[key]];
          break;
        default:
          outputDict[key] = inputDict[key];
          break;
      }
    }
    return outputDict;

  };

  //Verify user login
  useEffect(() => {
    setIsloading(true);
    const config = {
      headers: { "content-type": "application/json" },
    };

    Axios.get(`${apiUrl}/user`, {}, config)
      .then((response) => {
        setIsloading(false);
        console.log(response.data.user);
        setData(response.data.user);
        setDataFormated(renderParam(response.data.user));
      })
      .catch((error) => {
        console.log(error);
        setIsloading(false);
        window.location.href = "/app/login";
      });
  }, []);

  // Vrrify User Login End========>

  return (
    <>
      {isLoading && (
        <div className="d-flex align-items-center w-100 justify-content-center" style={{ height: "100lvh", zIndex: "99", position: "fixed", top: "0", left: "0", background: "rgba(255,255,255,0.5)" }}>
          <div className="loader"></div>
        </div>
      )}
      <div className="p-lg-3 p-1">
        <div className="bg-primary-opacity shadow pb-lg-1 pb-2 cardrounded">
          <div className="container">
            <div className="row pb-4 pt-2">
              <div className="col-lg-8 mx-auto  p-2" data-aos="fade-up" data-aos-delay="300">
                <div className="bg-white cardrounded  border-primary border border-1 shadow-sm h-100 p-4 d-flex align-items-center">
                  <div className="text-start w-100">
                    <span className="bg-white p-3 px-4 rounded-circle text-primary shadow-sm border-1 border border-primary fs-2 mb-4">
                      <i className="fa fa-user"></i>
                    </span>

                    {data.username && (
                      <>
                        <p className="text-primary fw-bold fs-6 mt-4">
                          <span className="text-muted"> Nom utilisateur:</span> {dataFromated.username}
                        </p>
                        <p className="text-primary fw-bold fs-6">
                          <span className="text-muted">Année de naissance:</span> {dataFromated.year_of_birth}
                        </p>
                        <p className="text-primary fw-bold fs-6 mt-2">
                          <span className="text-muted">Ville de résidence:</span> {dataFromated.city_of_living}{" "}
                        </p>
                        <p className="text-primary fw-bold fs-6">
                          <span className="text-muted">Personnes dans le foyer:</span> {dataFromated.people_per_household}
                        </p>
                        <p className="text-primary fw-bold fs-6">
                          <span className="text-muted">Superficie du foyer (m²):</span> {dataFromated.house_size}
                        </p>
                        <p className="text-primary fw-bold fs-6">
                          <span className="text-muted">Habitude de consommation:</span> {dataFromated.eating_habits}
                        </p>
                        <p className="text-primary fw-bold fs-6">
                          <span className="text-muted">Objectif d'empreinte carbone globale (Tonnes de CO2):</span> {dataFromated.co2_emissions_goal}
                        </p>
                        {dataFromated.has_car && (
                          <>
                            <hr class="solid"></hr>
                            <p className="text-primary fw-bold fs-6 mt-2">
                              <span className="text-muted">Année de contruction de votre voiture:</span> {dataFromated.car_construction_year}
                            </p>
                            <p className="text-primary fw-bold fs-6">
                              <span className="text-muted">Gabarit de la voiture: </span>
                              {dataFromated.car_size}
                            </p>
                            <p className="text-primary fw-bold fs-6">
                              <span className="text-muted">Type de voiture: </span>
                              {dataFromated.car_motorisation}
                            </p>
                            {dataFromated.car_motorisation !== "Electrique" && (
                              <div>
                                <p className="text-primary fw-bold fs-6">
                                  <span className="text-muted">Consommation moyenne de votre voiture (L/100km): </span>
                                  {dataFromated.car_consumption}
                                </p>
                                <p className="text-primary fw-bold fs-6">
                                  <span className="text-muted">Type de carburant: </span>
                                  {dataFromated.car_fuel}
                                </p>
                              </div>)}
                            <p className="text-primary fw-bold fs-6">
                              <span className="text-muted">Nombre moyen de voyageurs dans la voiture:</span> {dataFromated.car_passenger_number}
                            </p>
                          </>)}

                        <button className="button-63 btn-primary text-light" onClick={() => setIsForm(true)}>
                          Modifier les informations <i className="fa fa-save border-start border-0 border-light fs-3 py-0 my-0 ps-3 fw-bold ms-3"></i>
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isform && (
        <div
          className="d-flex position-fixed top-0 left-0 align-items-center justify-content-center"
          style={{ background: "rgba(255,255,255,0.2)", backdropFilter: "blur(5px)", position: "fixed", left: 0, top: 0, zIndex: 9999 }}
        >
          <div className="position-relative">
            <UpdateUser isform={(e) => setIsForm(e)} data={data} />
          </div>
        </div>
      )}
    </>
  );
};

export default UserDash;
