import React from "react";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { Outlet, Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { MdElectricBolt } from "react-icons/md";
import { IoCarSportSharp, IoAirplaneSharp, IoBus, IoTrainSharp, IoFlame, IoHelpCircleOutline } from "react-icons/io5";
import Axios from "axios";
const apiUrl = process.env.REACT_APP_BACKEND_URL;
Axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
Axios.defaults.xsrfCookieName = "csrftoken";
Axios.defaults.withCredentials = true;

const LastYear = (props) => {
  document.title = "Ecoolo - Année";

  const [response, setResponse] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(["uToken"]);
  const [next, setNext] = useState(false);
  const [iscar, setCar] = useState(localStorage.getItem("has_car") ? JSON.parse(localStorage.getItem("has_car")) : false);

  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = (today.getMonth() + 1).toString().padStart(2, "0"); // Pad single digit months with leading zero

  const [udata, setUdata] = useState({
    car_mileage: 0,
    long_hauleflight_hours: 0,
    gas_consumption: 0,
    short_hauleflight_hours: 0,
    electricity_consumption: 0,
    medium_hauleflight_hours: 0,
    long_hauleflight_hours: 0,
    train_mileage: 0,
    bus_hours: 0,
    year: currentYear - 1,
  });
  const [isLoading, setIsloading] = useState(false);
  const isDataValid = () => {
    for (const key in udata) {
      if (udata.hasOwnProperty(key) && (udata[key] === "" || udata[key] === null)) {
        return false;
      }
    }
    return true;
  };
  useEffect(() => {
    // if (cookies.uToken !== undefined) {
    //   window.location.href = "/app/";
    // }
    props.setsc(true);
  }, []);

  const handlesubmit = (e) => {
    e.preventDefault();
    console.log(udata);
    if (isDataValid()) {
      setIsloading(true);
      const config = {
        headers: { "content-type": "application/json" },
      };

      Axios.post(`${apiUrl}/add-user-last-year-consumption`, udata, config)
        .then((response) => {
          // setResponse(response.data.msg);
          // setUdata({ car_mileage: 0, long_hauleflight_hours: 0, gas_consumption: 0, electricity_consumption: 0, medium_hauleflight_hours: 0, long_hauleflight_hours: 0, train_mileage: 0 });
          toast.success("Succès");
          setIsloading(false);
          console.log(response.data);
          window.location.href = "/app/login";
        })
        .catch((error) => {
          setResponse(error.response.data.msg);
          toast.error("Une erreur s'est produite ");
          setIsloading(false);

          console.log(error);
        });
    } else {
      toast.error("Form fields are Empty");
    }
  };

  return (
    <>
      <Toaster />
      {isLoading && (
        <div className="d-flex align-items-center w-100 justify-content-center" style={{ height: "100lvh", zIndex: "99", position: "fixed", top: "0", left: "0", background: "rgba(255,255,255,0.5)" }}>
          <div className="loader"></div>
        </div>
      )}
      <div className="pattern d-flex align-items-center justify-content-center min-vh-100">
        <form id="uform" onSubmit={handlesubmit} className="row col-11 col-xl-8 col-lg-5 p-4 my-5 text-dark bg-light shadow-lg border-white border-3 border cardrounded" data-aos="fade-down">
          {!next && (
            <div className="row align-items-center">
              <div className="col-12 mb-3">
                <h4 className="fw-bold py-2">Dernière étape, vos consommations de l'année dernière ({udata.year})</h4>
                <p className="py-4">Afin de faire une estimation de vos consommations nous aimerions en savoir un peu plus sur ce que vous avez consommé l'année passée. Cela nous permettra de mieux identifier les réductions que vous faites.</p>
                {response !== "" && <p className="fw-bold text-info">{response}</p>}
                <hr />
              </div>
              {iscar && (
                <div className="col-12 mb-3 col-md-6">
                  <label for="car_mileage" className="form-label">
                    Nombre de kilomètres en voiture
                  </label>{' '}<IoCarSportSharp />
                  <input
                    type="number"
                    className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                    id="car_mileage"
                    placeholder="10"
                    autoComplete="off"
                    value={udata.car_mileage}
                    onChange={(e) => setUdata({ ...udata, car_mileage: parseInt(e.target.value, 10) })}
                    style={{ borderRadius: "10px" }}
                  />
                </div>
              )}
              <div className="col-12 mb-3 col-md-6">
                <label for="gas_consumption" className="form-label">
                  Consommation de gaz (en kWh)
                </label>{' '}<IoFlame />
                <input
                  type="number"
                  className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                  id="gas_consumption"
                  placeholder="10"
                  autoComplete="off"
                  value={udata.gas_consumption}
                  onChange={(e) => setUdata({ ...udata, gas_consumption: parseInt(e.target.value, 10) })}
                  style={{ borderRadius: "10px" }}
                />
              </div>
              <div className="col-12 col-md-6  mb-3">
                <label for="electricity_consumption" className="form-label">
                  Consommation d'électricité (en kWh)
                </label>{' '}<MdElectricBolt />
                <input
                  type="number"
                  className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                  id="electricity_consumption"
                  autoComplete="electricity_consumption"
                  placeholder="50"
                  value={udata.electricity_consumption}
                  onChange={(e) => setUdata({ ...udata, electricity_consumption: parseInt(e.target.value, 10) })}
                  style={{ borderRadius: "10px" }}
                />
              </div>
              <div className="col-12 col-md-6  mb-3 ">
                <label for="short_hauleflight_hours" className="form-label">
                  Heures d'avion court-courrier (moins de 2 heures)
                </label>{' '}<IoAirplaneSharp />
                <div class="hdg-label-popup">
                  text Tooltip
                </div>
                <input
                  type="number"
                  className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                  id="short_hauleflight_hours"
                  autoComplete="short_hauleflight_hours"
                  placeholder="5 hrs"
                  value={udata.short_hauleflight_hours}
                  onChange={(e) => setUdata({ ...udata, short_hauleflight_hours: parseInt(e.target.value, 10) })}
                  style={{ borderRadius: "10px" }}
                />
              </div>

              <div className="col-12 col-md-6 mb-3">
                <label for="medium_hauleflight_hours" className="form-label">
                  Heures d'avion moyen-courrier (entre 2 et 6 heures)
                </label>{' '}<IoAirplaneSharp />
                <input
                  type="number"
                  className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                  id="medium_hauleflight_hours"
                  autoComplete="medium_hauleflight_hours"
                  placeholder="10"
                  value={udata.medium_hauleflight_hours}
                  onChange={(e) => setUdata({ ...udata, medium_hauleflight_hours: parseInt(e.target.value, 10) })}
                  style={{ borderRadius: "10px" }}
                />
              </div>

              <div className="col-12 col-md-6 mb-3">
                <label for="long_hauleflight_hours" className="form-label">
                  Heures d'avion long-courrier (plus de 6 heures)
                </label>{' '}<IoAirplaneSharp />
                <input
                  type="number"
                  className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                  id="long_hauleflight_hours"
                  placeholder="5"
                  autoComplete="long_hauleflight_hours"
                  value={udata.long_hauleflight_hours}
                  onChange={(e) => setUdata({ ...udata, long_hauleflight_hours: parseInt(e.target.value, 10) })}
                  style={{ borderRadius: "10px" }}
                />
              </div>

              <div className="col-12 col-md-6 mb-3">
                <label for="bus_hours" className="form-label">
                  Heures de bus
                </label>{' '}<IoBus />
                <input
                  type="number"
                  className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                  id="bus_hours"
                  placeholder="5"
                  autoComplete="bus_hours"
                  value={udata.bus_hours}
                  onChange={(e) => setUdata({ ...udata, bus_hours: parseInt(e.target.value, 10) })}
                  style={{ borderRadius: "10px" }}
                />
              </div>

              <div className="col-12 col-md-6 mb-3">
                <label for="train_mileage" className="form-label">
                  Kilomètres en train
                </label>{' '}<IoTrainSharp />
                <input
                  type="number"
                  className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                  id="train_mileage"
                  placeholder="5"
                  autoComplete="train_mileage"
                  value={udata.train_mileage}
                  onChange={(e) => setUdata({ ...udata, train_mileage: parseInt(e.target.value, 10) })}
                  style={{ borderRadius: "10px" }}
                />
              </div>

              <div className={`col - 12`}>
                <button type="submit" className="button-63 btn-primary text-light">
                  Continuer
                </button>
              </div>
            </div>
          )}

          {/* 
          <p className="lead">
            Already have an Account?
            <Link to="/app/login" state={{ check: "Login" }} className="link-info text-decoration-none fw-bold">
              {" "}
              Login
            </Link>
          </p> */}
        </form>

        <Outlet />
      </div>
    </>
  );
};
export default LastYear;
