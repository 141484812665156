import React, { useEffect, useState } from "react";
import Axios from "axios";
Axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
Axios.defaults.xsrfCookieName = "csrftoken";
Axios.defaults.withCredentials = true;
const apiUrl = process.env.REACT_APP_BACKEND_URL;

const Admin = (props) => {
    const [isLoading, setIsloading] = useState(false);
    const [consumptionData, setConsumptionData] = useState([]);
    const [users, setUsers] = useState([]);
    useEffect(() => {
        // if (cookies.uToken !== undefined) {
        //   window.location.href = "/app/";
        // }
        props.setsc(false);
    }, []);

    useEffect(() => {
        setIsloading(true);
        const config = {
            headers: { "content-type": "application/json" },
        };

        Axios.get(`${apiUrl}/get-users`, {}, config)
            .then((response) => {
                setIsloading(false);
                console.log(response.data);
                setUsers(response.data);
            })
            .catch((error) => {
                console.log(error);
                setIsloading(false);
                window.location.href = "/app/login";
            });
    }, []);


    function MonthYearClassification({ data }) {
        // Your logic for classification by month and year here

        // Example data processing
        const classification = data.reduce((acc, item) => {
            const date = new Date(item.creation_date);
            const month = date.getMonth();
            const year = date.getFullYear();
            const key = `${month}-${year}`;

            if (!acc[key]) {
                acc[key] = [];
            }

            acc[key].push(item.value); // Push the value instead of the entire item
            return acc;
        }, {});

        // Example rendering of the classification
        return (
            <div>
                {Object.keys(classification).map(key => (
                    <div key={key}>
                        <h4>{`Date ${key}`} : {classification[key].length}</h4>
                    </div>
                ))}
            </div>
        );
    }

    useEffect(() => {
        setIsloading(true);
        const config = {
            headers: { "content-type": "application/json" },
        };

        Axios.get(`${apiUrl}/user-consumption-data`, {}, config)
            .then((response) => {
                setIsloading(false);
                console.log(response.data);
                setConsumptionData(response.data);
            })
            .catch((error) => {
                console.log(error);
                setIsloading(false);
                window.location.href = "/app/login";
            });
    }, []);



    return (
        <div>
            <div className="bg-light py-5">
                <div className="row">
                    <div className="col-lg-5 mx-auto ">
                        <div className=" w-100  d-flex align-items-center justify-content-center py-5 mx-0">
                            <p>Users:</p>
                        </div>
                        <div className=" w-100  d-flex align-items-center justify-content-center mx-0">
                            <ul>
                                {users.map((user) => {
                                    return <li> {user.username}: Creation date {user.account_creation_date.split("T")[0]}</li>;
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-5 mx-auto ">
                        <div className=" w-100  d-flex align-items-center justify-content-center py-5 mx-0">

                            <p>Consumption Data:</p>
                        </div>
                        <div className=" w-100  d-flex align-items-center justify-content-center mx-0">
                            <div>
                                <MonthYearClassification data={consumptionData} />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Admin;