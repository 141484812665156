import React from "react";
import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { Outlet, Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Axios from "axios";
const apiUrl = process.env.REACT_APP_BACKEND_URL;
Axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
Axios.defaults.xsrfCookieName = "csrftoken";
Axios.defaults.withCredentials = true;
const Login = (props) => {
  document.title = "Ecoolo - Login";

  const [cookies, setCookie, removeCookie] = useCookies(["uToken"]);
  const [response, setResponse] = useState([]);
  const [tresponse, setTresponse] = useState("");
  const [udata, setUdata] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    props.setsc(true);
  }, []);

  //Verify user login
  useEffect(() => {
    setIsloading(true);
    const config = {
      headers: { "content-type": "application/json" },
    };

    Axios.get(`${apiUrl}/user`, {}, config)
      .then((response) => {
        setIsloading(false);
        window.location.href = "/app/";
      })
      .catch((error) => {
        setIsloading(false);
      });
  }, []);

  // Verify User Login End========>

  const handlesubmit = (e) => {
    setIsloading(true);
    e.preventDefault();

    const config = {
      headers: { "content-type": "application/json" },
    };

    setTresponse("");
    Axios.post(`${apiUrl}/login`, udata, config)
      .then((response) => {
        // typeof response.data == "string" ? setTresponse(response.data) : setResponse(response.data);
        // response.length !== 0 && setCookie("uToken", response.data.access, { path: "/app/" });
        setUdata({ email: "", password: "" });
        toast.success("Succès");
        setIsloading(false);
        window.location.href = "/app/";
      })
      .catch((error) => {
        setResponse(error.response.data.msg);
        toast.error(error.response.data.msg);
        setIsloading(false);
      });
  };

  return (
    <>
      <Toaster />
      {isLoading && (
        <div className="d-flex align-items-center w-100 justify-content-center" style={{ height: "100lvh", zIndex: "99", position: "fixed", top: "0", left: "0", background: "rgba(255,255,255,0.5)" }}>
          <div className="loader"></div>
        </div>
      )}
      <div className="pattern">
        <div className="card min-vh-100" style={{ background: "transparent", border: "none" }}>
          <div className="d-flex h-100 align-items-center justify-content-center ">
            <form
              id="uform"
              onSubmit={handlesubmit}
              className="row g-3 col-11 col-lg-8 col-xl-5 p-lg-5 p-3 my-5 text-dark shadow-lg bg-light border-white border-3 border cardrounded"
              // style={{ background: "#36393f" }}
              data-aos="fade-down"
            >
              <h3 className="fw-bold">Bienvenue sur la page de connexion de l'application Ecoolo !</h3>
              {tresponse && <p className="fw-bold text-info">{tresponse}</p>}

              <hr />

              <div className="col-12">
                <label for="Email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control bg-light text-dark shadow-sm  p-3  border-secondary"
                  id="Email"
                  autoComplete="email"
                  value={udata.email}
                  onChange={(e) => setUdata({ ...udata, email: e.target.value })}
                  style={{ borderRadius: "10px" }}
                />
              </div>
              <div className="col-12">
                <label for="Password" className="form-label">
                  Mot de passe
                </label>
                <input
                  type="password"
                  className="form-control bg-light text-dark shadow-sm  p-3 border-secondary"
                  id="Password"
                  autoComplete="new-password"
                  value={udata.password}
                  onChange={(e) => setUdata({ ...udata, password: e.target.value })}
                  style={{ borderRadius: "10px" }}
                />
              </div>

              <div className="col-12 py-2" style={{ borderRadius: "10px" }}>
                <button type="submit" className="button-63 btn-primary text-light" style={{ borderRadius: "10px" }}>
                  Continuer
                </button>
              </div>
              <p className="lead">
                Vous n'avez pas de compte ?
                <Link to="/app/signup" state={{ check: "SignUp" }} className="link-info text-decoration-none fw-bold">
                  {" "}
                  Créer un compte
                </Link>
              </p>
            </form>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
