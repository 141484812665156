import React from "react";
import { useState, useEffect } from "react";
import Axios from "axios";
import { useCookies } from "react-cookie";
import { FcElectricity } from "react-icons/fc";
import { Outlet, Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
const apiUrl = process.env.REACT_APP_BACKEND_URL;
const UpdateYear = (props) => {
  document.title = "Ecoolo - Année";

  const [response, setResponse] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(["uToken"]);
  const [next, setNext] = useState(false);
  const [iscar, setCar] = useState(localStorage.getItem("has_car") ? JSON.parse(localStorage.getItem("has_car")) : false);

  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = (today.getMonth() + 1).toString().padStart(2, "0"); // Pad single digit months with leading zero

  const [udata, setUdata] = useState({
    car_mileage: 0,
    long_hauleflight_hours: 0,
    gas_consumption: 0,
    short_hauleflight_hours: 0,
    electricity_consumption: 0,
    medium_hauleflight_hours: 0,
    long_hauleflight_hours: 0,
    train_mileage: 0,
    year: currentYear - 1,
  });
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    // if (cookies.uToken !== undefined) {
    //   window.location.href = "/app/";
    // }
  }, []);

  const handlesubmit = (e) => {
    e.preventDefault();
    console.log(udata);

    setIsloading(true);
    const config = {
      headers: { "content-type": "application/json" },
    };

    Axios.patch(`${apiUrl}/update-user-consumption`, udata, config)
      .then((response) => {
        // setResponse(response.data);
        // setUdata({ car_mileage: 0, long_hauleflight_hours: 0, gas_consumption: 0, electricity_consumption: 0, medium_hauleflight_hours: 0, long_hauleflight_hours: 0, train_mileage: 0 });
        toast.success("Succès");
        setIsloading(false);
        console.log(response.data);
        // window.location.href = "/app/login";
      })
      .catch((error) => {
        setResponse(error.message);
        toast.error("Une erreur s'est produite ");
        setIsloading(false);

        console.log(error);
      });
  };

  return (
    <>
      <Toaster />
      {isLoading && (
        <div className="d-flex align-items-center w-100 justify-content-center" style={{ height: "100lvh", zIndex: "99", position: "fixed", top: "0", left: "0", background: "rgba(255,255,255,0.5)" }}>
          <div className="loader"></div>
        </div>
      )}
      <div className="pattern d-flex align-items-md-center justify-content-center" style={{ height: "100vh", overflow: "auto" }}>
        <div className=" row mx-0 ">
          <form id="uform" onSubmit={handlesubmit} className="col-12 col-xl-8 col-lg-5 mx-auto  p-lg-4 my-5 text-dark bg-light shadow-lg border-white border-3 border cardrounded" data-aos="fade-down">
            {!next && (
              <div className="row">
                <div className="col-12 mb-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="fw-bold py-4">Update Year Consumption</h4>
                    <button className="btn-dark btn btn-lg rounded-circle" onClick={() => props.isform(false)}>
                      <i className="fa fa-times "></i>
                    </button>
                  </div>

                  {response !== "" && <p className="fw-bold text-info">{response}</p>}
                  <hr />
                </div>
                {iscar && (
                  <div className="col-12 mb-3 col-md-6">
                    <label for="car_mileage" className="form-label">
                      Nombre de kilomètres en voiture
                    </label>
                    <input
                      type="number"
                      className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                      id="car_mileage"
                      placeholder="10"
                      autoComplete="off"
                      value={udata.car_mileage}
                      onChange={(e) => setUdata({ ...udata, car_mileage: parseInt(e.target.value, 10) })}
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
                <div className="col-12 mb-3 col-md-6">
                  <label for="gas_consumption" className="form-label">
                    Consommation de gaz
                  </label>
                  <input
                    type="number"
                    className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                    id="gas_consumption"
                    placeholder="10"
                    autoComplete="off"
                    value={udata.gas_consumption}
                    onChange={(e) => setUdata({ ...udata, gas_consumption: parseInt(e.target.value, 10) })}
                    style={{ borderRadius: "10px" }}
                  />
                </div>
                <div className="col-12 col-md-6  mb-3">
                  <label for="electricity_consumption" className="form-label">
                    Consommation d'électricité
                  </label><FcElectricity />
                  <input
                    type="number"
                    className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                    id="electricity_consumption"
                    autoComplete="electricity_consumption"
                    placeholder="50"
                    value={udata.electricity_consumption}
                    onChange={(e) => setUdata({ ...udata, electricity_consumption: parseInt(e.target.value, 10) })}
                    style={{ borderRadius: "10px" }}
                  />
                </div>
                <div className="col-12 col-md-6  mb-3 ">
                  <label for="short_hauleflight_hours" className="form-label">
                    Heures d'avion court-courrier
                  </label>
                  <input
                    type="number"
                    className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                    id="short_hauleflight_hours"
                    autoComplete="short_hauleflight_hours"
                    placeholder="5 hrs"
                    value={udata.short_hauleflight_hours}
                    onChange={(e) => setUdata({ ...udata, short_hauleflight_hours: parseInt(e.target.value, 10) })}
                    style={{ borderRadius: "10px" }}
                  />
                </div>

                <div className="col-12 col-md-6 mb-3">
                  <label for="medium_hauleflight_hours" className="form-label">
                    Heures d'avion moyen-courrier
                  </label>
                  <input
                    type="number"
                    className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                    id="medium_hauleflight_hours"
                    autoComplete="medium_hauleflight_hours"
                    placeholder="10"
                    value={udata.medium_hauleflight_hours}
                    onChange={(e) => setUdata({ ...udata, medium_hauleflight_hours: parseInt(e.target.value, 10) })}
                    style={{ borderRadius: "10px" }}
                  />
                </div>

                <div className="col-12 col-md-6 mb-3">
                  <label for="long_hauleflight_hours" className="form-label">
                    Heures d'avion long-courrier
                  </label>
                  <input
                    type="number"
                    className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                    id="long_hauleflight_hours"
                    placeholder="5"
                    autoComplete="long_hauleflight_hours"
                    value={udata.long_hauleflight_hours}
                    onChange={(e) => setUdata({ ...udata, long_hauleflight_hours: parseInt(e.target.value, 10) })}
                    style={{ borderRadius: "10px" }}
                  />
                </div>

                <div className="col-12 col-md-6 mb-3">
                  <label for="bus_hours" className="form-label">
                    Heures de bus
                  </label>
                  <input
                    type="number"
                    className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                    id="bus_hours"
                    placeholder="5"
                    autoComplete="bus_hours"
                    value={udata.bus_hours}
                    onChange={(e) => setUdata({ ...udata, bus_hours: parseInt(e.target.value, 10) })}
                    style={{ borderRadius: "10px" }}
                  />
                </div>

                <div className="col-12 col-md-6 mb-3">
                  <label for="train_mileage" className="form-label">
                    Kilomètres en train
                  </label>
                  <input
                    type="number"
                    className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                    id="train_mileage"
                    placeholder="5"
                    autoComplete="train_mileage"
                    value={udata.train_mileage}
                    onChange={(e) => setUdata({ ...udata, train_mileage: parseInt(e.target.value, 10) })}
                    style={{ borderRadius: "10px" }}
                  />
                </div>

                <div className={`col - 12 mb - 3 py - 2 `}>
                  <button type="submit" className="button-63 btn-primary text-light">
                    Continuer
                  </button>
                </div>
              </div>
            )}

            {/* 
          <p className="lead">
            Already have an Account?
            <Link to="/app/login" state={{ check: "Login" }} className="link-info text-decoration-none fw-bold">
              {" "}
              Login
            </Link>
          </p> */}
          </form>
        </div>

        <Outlet />
      </div>
    </>
  );
};
export default UpdateYear;
