import React, { useEffect, useState } from "react";
import Lchart from "./chartdemo";
import Simulation from "./simulation";
import Axios from "axios";
const apiUrl = process.env.REACT_APP_BACKEND_URL;
Axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
Axios.defaults.xsrfCookieName = "csrftoken";
Axios.defaults.withCredentials = true;

const Projections = (props) => {
  document.title = "Ecoolo - Simulation";
  const [isLoading, setIsloading] = useState(false);
  const [userdata, setUserData] = useState({});
  const [simproj, setSimProj] = useState({ 'footprint': 0 });
  useEffect(() => {
    props.setsc(false);
  }, []);

  //Verify user login
  useEffect(() => {
    setIsloading(true);
    const config = {
      headers: { "content-type": "application/json" },
    };

    Axios.get(`${apiUrl}/user`, {}, config)
      .then((response) => {
        setIsloading(false);
        console.log(response.data);
        setUserData(response.data.user);
      })
      .catch((error) => {
        console.log(error);
        setIsloading(false);
        window.location.href = "/app/login";
      });
  }, []);

  // Vrrify User Login End========>

  return (
    <>
      {isLoading && (
        <div className="d-flex align-items-center w-100 justify-content-center" style={{ height: "100lvh", zIndex: "99", position: "fixed", top: "0", left: "0", background: "rgba(255,255,255,0.5)" }}>
          <div className="loader"></div>
        </div>
      )}
      <div className="p-lg-3 p-1">
        <div className="bg-primary-opacity shadow-lg pb-lg-1 pb-2 cardrounded">
          <div className="container">
            <div className="row pb-4 pt-2">
              <div className="col-lg-5  p-2" data-aos="fade-up" data-aos-delay="300">
                <div className="bg-white cardrounded  border-primary border border-1 shadow-lg h-100 p-4 d-flex align-items-center">
                  <div className="text-center w-100">
                    <span className="bg-white p-3 rounded-circle text-primary shadow-sm border-1 border border-primary fs-2">
                      <i className="fa fa-cloud"></i>
                    </span>
                    {simproj.footprint !== 0 ? (
                      <>
                        <h4 className="text-dark fw-bold display-1 pt-2"> {simproj.footprint.toFixed(0)} kg </h4>
                        <p className="text-muted py-0 my-0 fw-bold lead">Simulation estimée</p>
                      </>
                    ) : (
                      <>
                        <h4 className="text-dark fw-bold display-1 pt-2"> - </h4>
                        <p className="text-muted py-0 my-0 fw-bold lead">Effetctuez une simulation</p>
                      </>
                    )}

                    <div className="img">
                      <img
                        src="https://img.freepik.com/free-vector/net-zero-concept-illustration_114360-9308.jpg?w=1380&t=st=1707761356~exp=1707761956~hmac=0fac1a54abef8d52e18c79fba25fa77c3b63421732893f27760a91e06144cd15"
                        class="img-fluid"
                        alt="..."
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-7  p-2" data-aos="fade-up" data-aos-delay="300">
                <Simulation setsim={(e) => setSimProj(e)} />
              </div>

              <div className="col-xl-8 col-lg-10 col-12 mx-auto mt-3" data-aos="fade-up" data-aos-delay="400">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="text-center bg-dark-opacity cardrounded pt-4 px-3 w-100 me-3 border-primary border border-1 shadow-sm">
                    <span className="bg-white text-primary p-3 rounded-circle text-white fs-4 my-2">
                      <i className="fa fa-leaf"></i>
                    </span>
                    <h5 className="fw-bold fs-3 mb-0 pb-0 mt-3 text-primary">
                      {" "}
                      {userdata.co2_emissions_goal}T CO2
                    </h5>
                    <p class="text-lead fw-bold  text-muted ">Objectif fixé</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Projections;
