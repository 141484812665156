import React from "react";
import { useState, useEffect } from "react";
import Axios from "axios";
import { useCookies } from "react-cookie";
import { MdElectricBolt } from "react-icons/md";
import { IoCarSportSharp, IoAirplaneSharp, IoBus, IoTrainSharp, IoFlame } from "react-icons/io5";
import { Outlet, Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
const apiUrl = process.env.REACT_APP_BACKEND_URL;
const MonthConsume = (props) => {
  document.title = "Ecoolo - Consommation du mois";
  Axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
  Axios.defaults.xsrfCookieName = "csrftoken";
  Axios.defaults.withCredentials = true;

  const [response, setResponse] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(["uToken"]);
  const [iscar, setCar] = useState(localStorage.getItem("has_car") ? JSON.parse(localStorage.getItem("has_car")) : false);

  const today = new Date();
  const [lastMonthAdded, setLastMonthAdded] = useState(props.lastMonthAdded);
  const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"];

  const [udata, setUdata] = useState({
    month: lastMonthAdded + 1,
    car_mileage: 0,
    long_hauleflight_hours: 0,
    gas_consumption: 0,
    short_hauleflight_hours: 0,
    electricity_consumption: 0,
    medium_hauleflight_hours: 0,
    long_hauleflight_hours: 0,
    train_mileage: 0,
    bus_hours: 0,
    year: props.year,
  });
  const [isLoading, setIsloading] = useState(false);

  const validData = () => {
    if (udata.electricity_consumption === 0) {
      toast.error("Vous devez renseigner une consommation électrique ");
      return false;
    }
    return true;
  }

  const handlesubmit = (e) => {
    e.preventDefault();
    if (validData()) {
      setIsloading(true);
      const config = {
        headers: { "content-type": "application/json" },
      };
      Axios.post(`${apiUrl}/add-user-consumption`, udata, config)
        .then((response) => {
          // setResponse(response.data);
          // setUdata({ car_mileage: 0, long_hauleflight_hours: 0, gas_consumption: 0, electricity_consumption: 0, medium_hauleflight_hours: 0, long_hauleflight_hours: 0, train_mileage: 0 });
          toast.success("Succès");
          setIsloading(false);
          setTimeout(() => {
            window.location.href = "/app/";
          }, 1000);
        })
        .catch((error) => {
          setResponse(error.response.data.msg);
          toast.error("Une erreur s'est produite ");
          setIsloading(false);

          console.log(error);
        });
    }
  };

  return (
    <>
      <Toaster />
      {isLoading && (
        <div className="d-flex align-items-center w-100 justify-content-center" style={{ height: "100lvh", zIndex: "99", position: "fixed", top: "0", left: "0", background: "rgba(255,255,255,0.5)" }}>
          <div className="loader"></div>
        </div>
      )}
      <div className="pattern d-flex align-items-md-center justify-content-center" style={{ height: "100vh", overflow: "auto" }}>
        <div className="row my-auto mx-4">
          <form id="uform" onSubmit={handlesubmit} className="col-12 col-xl-8 col-lg-8 mx-auto p-lg-4 p-4 my-5 text-dark bg-light shadow-lg border-white border-3 border cardrounded">
            <div className="row align-items-center">
              <div className="col-12 mb-3">
                <div className="d-flex align-items-center justify-content-between">
                  <h4 className="fw-bold py-4 mb-0">Ajouter une consommation pour {months[lastMonthAdded]} {props.year}</h4>
                  <button className="btn-dark btn btn-lg rounded-circle" onClick={() => props.isform(false)}>
                    <i className="fa fa-times "></i>
                  </button>
                </div>

                {response !== "" && <p className="fw-bold text-info">{response}</p>}
                <hr />
              </div>
              {iscar && (
                <div className="col-12 mb-3 col-md-6">
                  <label for="car_mileage" className="form-label">
                    Nombre de kilomètres en voiture
                  </label>{' '}<IoCarSportSharp />
                  <input
                    type="number"
                    className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                    id="car_mileage"
                    placeholder="10"
                    autoComplete="off"
                    value={udata.car_mileage}
                    onChange={(e) => setUdata({ ...udata, car_mileage: parseInt(e.target.value, 10) })}
                    style={{ borderRadius: "10px" }}
                  />
                </div>
              )}
              <div className="col-12 mb-3 col-md-6">
                <label for="gas_consumption" className="form-label">
                  Consommation de gaz (en kWh)
                </label>{' '}<IoFlame />
                <input
                  type="number"
                  className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                  id="gas_consumption"
                  placeholder="10"
                  autoComplete="off"
                  value={udata.gas_consumption}
                  onChange={(e) => setUdata({ ...udata, gas_consumption: parseInt(e.target.value, 10) })}
                  style={{ borderRadius: "10px" }}
                />
              </div>
              <div className="col-12 col-md-6  mb-3">
                <label for="electricity_consumption" className="form-label">
                  Consommation d'électricité (en kWh)
                </label>{' '}<MdElectricBolt />
                <input
                  type="number"
                  className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                  id="electricity_consumption"
                  autoComplete="electricity_consumption"
                  placeholder="50"
                  value={udata.electricity_consumption}
                  onChange={(e) => setUdata({ ...udata, electricity_consumption: parseInt(e.target.value, 10) })}
                  style={{ borderRadius: "10px" }}
                />
              </div>
              <div className="col-12 col-md-6  mb-3 ">
                <label for="short_hauleflight_hours" className="form-label">
                  Heures d'avion court-courrier
                </label>{' '}<IoAirplaneSharp />
                <input
                  type="number"
                  className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                  id="short_hauleflight_hours"
                  autoComplete="short_hauleflight_hours"
                  placeholder="5 hrs"
                  value={udata.short_hauleflight_hours}
                  onChange={(e) => setUdata({ ...udata, short_hauleflight_hours: parseInt(e.target.value, 10) })}
                  style={{ borderRadius: "10px" }}
                />
              </div>

              <div className="col-12 col-md-6 mb-3">
                <label for="medium_hauleflight_hours" className="form-label">
                  Heures d'avion moyen-courrier
                </label>{' '}<IoAirplaneSharp />
                <input
                  type="number"
                  className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                  id="medium_hauleflight_hours"
                  autoComplete="medium_hauleflight_hours"
                  placeholder="10"
                  value={udata.medium_hauleflight_hours}
                  onChange={(e) => setUdata({ ...udata, medium_hauleflight_hours: parseInt(e.target.value, 10) })}
                  style={{ borderRadius: "10px" }}
                />
              </div>

              <div className="col-12 col-md-6 mb-3">
                <label for="long_hauleflight_hours" className="form-label">
                  Heures d'avion long-courrier
                </label>{' '}<IoAirplaneSharp />
                <input
                  type="number"
                  className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                  id="long_hauleflight_hours"
                  placeholder="5"
                  autoComplete="long_hauleflight_hours"
                  value={udata.long_hauleflight_hours}
                  onChange={(e) => setUdata({ ...udata, long_hauleflight_hours: parseInt(e.target.value, 10) })}
                  style={{ borderRadius: "10px" }}
                />
              </div>

              <div className="col-12 col-md-6 mb-3">
                <label for="bus_hours" className="form-label">
                  Heures de bus
                </label>{' '}<IoBus />
                <input
                  type="number"
                  className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                  id="bus_hours"
                  autoComplete="bus_hours"
                  value={udata.bus_hours}
                  onChange={(e) => setUdata({ ...udata, bus_hours: parseInt(e.target.value, 10) })}
                  style={{ borderRadius: "10px" }}
                />
              </div>

              <div className="col-12 col-md-6 mb-3">
                <label for="train_mileage" className="form-label">
                  Kilomètres en train
                </label>{' '}<IoTrainSharp />
                <input
                  type="number"
                  className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                  id="train_mileage"
                  placeholder="5"
                  autoComplete="train_mileage"
                  value={udata.train_mileage}
                  onChange={(e) => setUdata({ ...udata, train_mileage: parseInt(e.target.value, 10) })}
                  style={{ borderRadius: "10px" }}
                />
              </div>

              <div className={`col - 12`}>
                <button type="submit" className="button-63 btn-primary text-light">
                  Ajouter
                </button>
              </div>
            </div>

            {/* 
          <p className="lead">
            Already have an Account?
            <Link to="/app/login" state={{ check: "Login" }} className="link-info text-decoration-none fw-bold">
              {" "}
              Login
            </Link>
          </p> */}
          </form>
        </div>

        <Outlet />
      </div >
    </>
  );
};
export default MonthConsume;
