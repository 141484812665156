import React, { useEffect, useState } from "react";
import Lchart from "./chartdemo";
import UpdateMonthConsume from "./auth/udpdatemonth";
import { Outlet, Link } from "react-router-dom";
import Axios from "axios";
const apiUrl = process.env.REACT_APP_BACKEND_URL;
Axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
Axios.defaults.xsrfCookieName = "csrftoken";
Axios.defaults.withCredentials = true;
const Detail = (props) => {
  //Verify user login
  useEffect(() => {
    const config = {
      headers: { "content-type": "application/json" },
    };

    Axios.get(`${apiUrl}/user`, {}, config)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);

        window.location.href = "/app/login";
      });
  }, []);

  // Vrrify User Login End========>

  useEffect(() => {
    if (!localStorage.getItem("detdata")) {
      window.location.href = "/app/";
    }
    props.setsc(false);
  }, []);
  const [isform, setIsForm] = useState(false);
  const [data, setData] = useState(localStorage.getItem("detdata") ? JSON.parse(localStorage.getItem("detdata")) : {});
  const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"];

  const updateData = (newData) => {
    setData(newData);
  };

  return (
    <div className="p-lg-3 p-1">
      <div className="bg-primary-opacity shadow pb-lg-1 pb-2 cardrounded">
        <Link to="/app/" type="button" className="btn ms-3 fw-bold py-2 btn-lg btn-dark text-light my-4">
          Retour <i className="fa fa-arrow-circle-left ms-3"></i>
        </Link>
        <div className="container">
          <div className="row pb-2 pt-2">
            <div className="col-lg-8 col-12">
              <div className="bg-white cardrounded border-primary border border-1 shadow-sm h-100 p-4 d-flex">
                <div style={{ maxHeight: "500px", height: '500px', width: '100%' }}>
                  <Lchart cType="dough" datachart={data} />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12  p-2">
              <div className="bg-white cardrounded  border-primary border border-1 shadow-sm h-100 p-4 d-flex align-items-center">
                <div className="text-center w-100">
                  <span className="bg-white p-3 rounded-circle text-primary shadow-sm border-1 border border-primary fs-2 mb-4">
                    <i className="fa fa-cloud"></i>
                  </span>
                  {data.monthly_footprint && (
                    <>
                      <p className="text-muted py-0 my-0 fw-bold lead mt-4">{months[data.month - 1]} {data.year}</p>
                      <h3 className="text-dark fw-bold display-1"> {data.monthly_footprint.toFixed(0)} kg</h3>
                    </>
                  )}
                  <div className="img">
                    <img
                      src="https://img.freepik.com/free-vector/net-zero-concept-illustration_114360-9308.jpg?w=1380&t=st=1707761356~exp=1707761956~hmac=0fac1a54abef8d52e18c79fba25fa77c3b63421732893f27760a91e06144cd15"
                      class="img-fluid"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-100 my-4">
          <button className="button-63 multi-line-btn d-flex align-items-center justify-content-between mt-5 mx-auto" onClick={() => setIsForm(true)}>
            Mettre à jour les données de ce mois <i className="fa fa-cog border-start border-0 border-light fs-3 py-0 my-0 ps-3 fw-bold ms-3"></i>
          </button>
        </div>
      </div>


      {isform && (
        <div
          className="d-flex position-fixed top-0 left-0 align-items-center justify-content-center"
          style={{ background: "rgba(255,255,255,0.2)", backdropFilter: "blur(5px)", position: "fixed", left: 0, top: 0, zIndex: 9999 }}
        >
          <div className="position-relative">
            <UpdateMonthConsume isform={(e) => setIsForm(e)} data={data} month={data.month} updateData={updateData} />
          </div>
        </div>
      )}

      {/* Activity Adding feature for future */}
      {/* <div className="container mt-5 pt-5">
        <div className="d-flex aling-items-center justify-content-between" data-aos="fade-up">
          <h5 className="fs-3 fw-bold py-3">Latest Activities</h5>
          <div>
            <button className="btn-primary btn btn-lg d-flex align-items-center justify-content-between">
              Add Activity <i className="fa fa-plus-circle border-start border-0 border-light fs-3 py-0 my-0 ps-3 fw-bold ms-3"></i>
            </button>
          </div>
        </div>

        <ul class="list-group  w-100">
          <li class="list-group-item px-1 px-lg-auto py-3 border-0 border-bottom bg-none d-flex align-items-center justify-content-between" data-aos="fade-up" data-aos-dealay="100">
            <div className="start d-flex align-items-center">
              <span
                className="bg-light text-primary fs-2  me-4 d-flex align-items-center justify-content-center border-1 border-primary border"
                style={{ height: "70px", width: "70px", borderRadius: "100%" }}
              >
                <i class="fa fa-bicycle fa-fw "></i>
              </span>

              <div className="text">
                <p className="fw-bold text-dark text-lead pb-0 mb-0"> 12 km Bicycle</p>
                <span className="text-muted fw-bold">360 n2c point</span>
              </div>
            </div>

            <div className="text">
              <span className="fw-bold fs-5 pb-0 mb-0 me-1 text-primary"> -400gm</span>
              <span className="text-muted fw-bold fs-5">Co2</span>
            </div>
          </li>

          <li class="list-group-item px-1 px-lg-auto py-3 border-0 border-bottom bg-none d-flex align-items-center justify-content-between" data-aos="fade-up" data-aos-dealay="150">
            <div className="start d-flex align-items-center">
              <span
                className="bg-light text-primary fs-2  me-4 d-flex align-items-center justify-content-center border-1 border-primary border"
                style={{ height: "70px", width: "70px", borderRadius: "100%" }}
              >
                <i class="fa fa-laptop fa-fw "></i>
              </span>

              <div className="text">
                <p className="fw-bold text-dark text-lead pb-0 mb-0"> 12 km Bicycle</p>
                <span className="text-muted fw-bold">360 n2c point</span>
              </div>
            </div>

            <div className="text">
              <span className="fw-bold fs-5 pb-0 mb-0 me-1 text-primary"> -400gm</span>
              <span className="text-muted fw-bold fs-5">Co2</span>
            </div>
          </li>

          <li class="list-group-item px-1 px-lg-auto py-3 border-0 border-bottom bg-none d-flex align-items-center justify-content-between" data-aos="fade-up" data-aos-dealay="200">
            <div className="start d-flex align-items-center">
              <span
                className="bg-light text-primary fs-2  me-4 d-flex align-items-center justify-content-center border-1 border-primary border"
                style={{ height: "70px", width: "70px", borderRadius: "100%" }}
              >
                <i class="fa fa-car fa-fw "></i>
              </span>

              <div className="text">
                <p className="fw-bold text-dark text-lead pb-0 mb-0"> 30 km Car</p>
                <span className="text-muted fw-bold">360 n2c point</span>
              </div>
            </div>

            <div className="text">
              <span className="fw-bold fs-5 pb-0 mb-0 me-1 text-primary"> -400gm</span>
              <span className="text-muted fw-bold fs-5">Co2</span>
            </div>
          </li>

          <li class="list-group-item px-1 px-lg-auto py-3 border-0 border-bottom bg-none d-flex align-items-center justify-content-between" data-aos="fade-up" data-aos-dealay="250">
            <div className="start d-flex align-items-center">
              <span
                className="bg-light text-primary fs-2  me-4 d-flex align-items-center justify-content-center border-1 border-primary border"
                style={{ height: "70px", width: "70px", borderRadius: "100%" }}
              >
                <i class="fa fa-child fa-fw "></i>
              </span>

              <div className="text">
                <p className="fw-bold text-dark text-lead pb-0 mb-0"> 6 km Walking</p>
                <span className="text-muted fw-bold">360 n2c point</span>
              </div>
            </div>

            <div className="text">
              <span className="fw-bold fs-5 pb-0 mb-0 me-1 text-primary"> -120gm</span>
              <span className="text-muted fw-bold fs-5">Co2</span>
            </div>
          </li>
        </ul>
      </div> */}
      <Outlet />
    </div>
  );
};

export default Detail;
