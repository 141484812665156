import React, { useState, useEffect } from 'react';

const CookieBanner = () => {
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        // Vérifier si l'utilisateur a déjà accepté les cookies dans le stockage local
        const acceptedCookies = localStorage.getItem('acceptedCookies');
        setShowBanner(acceptedCookies !== 'true');
    }, []);

    const handleAccept = () => {
        // Mettre à jour l'état pour indiquer que la bannière ne doit plus être affichée
        setShowBanner(false);
        // Enregistrer dans le stockage local que l'utilisateur a accepté les cookies
        localStorage.setItem('acceptedCookies', 'true');
    };

    // Rendre la bannière uniquement si showBanner est vrai
    return (
        showBanner && (
            <div className="cookie-banner">
                <p>Nous utilisons des cookies uniquement pour stocker des données techniques nécessaires pour améliorer votre expérience sur notre site. En continuant à utiliser ce site, vous acceptez notre utilisation des cookies.</p>
                <button onClick={handleAccept}>Accepter</button>
            </div>
        )
    );
};

export default CookieBanner;