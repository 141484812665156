import React, { useEffect, useState } from "react";
import "../styles/_master.scss"

const AppDetails = (props) => {
    document.title = "Ecoolo - Détails";
    useEffect(() => {
        // if (cookies.uToken !== undefined) {
        //   window.location.href = "/app/";
        // }
        props.setsc(false);
    }, []);

    return (
        <div>
            <div className="col-11 col-md-8 mx-auto">
                <div className="bg-primary-opacity shadow cardrounded">
                    <div className="row m-4 p-2 p-xs-4 ">
                        <div className="col-lg-12 mx-auto ">
                            <h1 className="fw-bold py-4 text-center">Comment sont fait les calculs ?</h1>
                            <p className="text-center">Nous utilisons des sources de données reconnues telles que la <strong>Base Carbone issue de l'ADEME</strong> (l'Agence De l'Environnement et de la Maîtrise de l'Energie) afin d'évaluer chaque type de dépense et pouvoir calculer de manière précise les émissions engendrées par vos consommations. Pour l'instant les émissions sont découpés en 3 catégories différentes:</p>
                            <ul>
                                <li className="list-unstyled">
                                    <p><strong>Logement</strong>: Tout ce qui regroupe vos émissions liés à vos dépenses liés à votre logement, construction, équipements, consommation d'électricite et de gaz.</p>
                                </li>
                                <li className="list-unstyled">
                                    <p><strong>Transports</strong>: Tout ce qui regroupe vos émissions liés à vos modes de transport (train, voiture, avion).</p>
                                </li>
                                <li className="list-unstyled">
                                    <p><strong>Autre</strong>: Tout les autres dépenses liés à vos habitudes alimentaires, l'impact induit par l'utilisation des services publiques, etc...</p>
                                </li>
                            </ul>


                        </div>
                    </div>
                </div>
            </div>
            <div className="col-11 col-md-8 mx-auto">
                <div className="bg-primary-opacity shadow cardrounded">
                    <div className="row m-4  m-4 p-2 p-xs-4">
                        <div className="col-lg-12 mx-auto ">
                            <h1 className="fw-bold py-4 text-center">Un exemple</h1>
                            <p className="text-center">Prenons l'exemple pour le calcul d'une émission mensuelle pour une personne végétarienne ayant fait 2h en avion court-courrier, 300 kilomètres en voiture et consommé 500kWh d'électricité.</p>
                            <ul>
                                <li className="list-unstyled">
                                    <p><strong>Logement</strong>: Selon la base de donnée de <strong>l'ADEME</strong> , 1kWh d'électricité en France émet l'équivalent de 0.052 kgCO2. On obtient donc 26 kgCO2.</p>
                                </li>
                                <li className="list-unstyled"><strong>Transports</strong>:</li>
                                <ul>
                                    <li>
                                        <p>Pour 1h d'avion court-courrier, l'équivalent C02 est de 0.259 kg/km. On estime en moyenne qu'un avion court-courrier vole à une vitesse de 460km/h ce qui nous donne 239 kgCO2.</p>
                                    </li>
                                    <li>
                                        <p>Pour ce qui est de la voiture le calcul est plus compliqué (cela dépend du modèle, de la motorisation, etc...). Dans le cas d'une voiture de taille moyenne, thermique roulant au diesel l'équivalent C02 est de 3 kgCO2/L. Pour une consommation moyenne de 6 L/100km pour ce type de voiture cela nous donne une émission 54 kgCO2</p>
                                    </li>
                                </ul>
                                <li className="list-unstyled">
                                    <p><strong>Autre</strong>: Pour ce qui est des autres dépenses (alimentaires, services publics) nous nous basons sur les données moyennes retournées par <strong>l'ADEME</strong> (entre 1 et 5 kg de C02 par repas, 2,6T CO2 par an et par habitant pour les services publics).</p>
                                </li>
                            </ul>


                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
};

export default AppDetails;