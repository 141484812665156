import React, { useEffect, useState } from "react";
import Lchart from "./chartdemo";
import Axios from "axios";
const apiUrl = process.env.REACT_APP_BACKEND_URL;
Axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
Axios.defaults.xsrfCookieName = "csrftoken";
Axios.defaults.withCredentials = true;

const Evolutions = (props) => {
  document.title = "Ecoolo - Statistiques";
  const [isLoading, setIsloading] = useState(false);
  const [data, setData] = useState([]);
  const [response, setResponse] = useState("");
  const [userMeanRatio, setUserMeanRatio] = useState(100);
  const [userTransportRatio, setUserTransportRatio] = useState(100);
  const [userEnergyRatio, setUserEnergyRatio] = useState(100);

  useEffect(() => {
    props.setsc(false);
  }, []);

  useEffect(() => {
    setIsloading(true);
    const config = {
      headers: { "content-type": "application/json" },
    };

    Axios.get(`${apiUrl}/get-user-footprint-evolution`, {}, config)
      .then((response) => {
        setIsloading(false);
        setData(response.data);
      })
      .catch((error) => {
        setResponse(error.response.data.msg);
        console.log(error);
        setIsloading(false);
      });
  }, []);

  useEffect(() => {
    const config = {
      headers: { "content-type": "application/json" },
    };

    Axios.get(`${apiUrl}/compare-user-footprint`, {}, config)
      .then((response) => {
        // console.log(response);
        const dbValues = {};
        const userValues = {};

        Object.keys(response.data).forEach((key) => {
          if (key.startsWith("db_")) {
            dbValues[key.substring(3)] = response.data[key];
          } else if (key.startsWith("user_")) {
            userValues[key.substring(5)] = response.data[key];
          }
        });

        // Set the separated values into state variables
        setUserMeanRatio((userValues['footprint_mean'] * 100) / dbValues['footprint_mean']);
        setUserTransportRatio((userValues['transport_footprint_mean'] * 100) / dbValues['transport_footprint_mean']);
        setUserEnergyRatio((userValues['energy_footprint_mean'] * 100) / dbValues['energy_footprint_mean']);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);



  //Verify user login
  useEffect(() => {
    setIsloading(true);
    const config = {
      headers: { "content-type": "application/json" },
    };

    Axios.get(`${apiUrl}/user`, {}, config)
      .then((response) => {
        setIsloading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsloading(false);
        window.location.href = "/app/login";
      });
  }, []);

  // Vrrify User Login End========>
  function ColoredText({ ratio }) {
    let textColor = '';
    let text = '';
    if (ratio < 100) {
      textColor = 'text-green fw-bold display-1';
      text = 100 - ratio;
    } else {
      textColor = 'text-red fw-bold display-1';
      text = ratio - 100
    }

    return <p className={textColor}>{text} %</p>;
  }

  return (
    <>
      {isLoading && (
        <div className="d-flex align-items-center w-100 justify-content-center" style={{ height: "100lvh", zIndex: "99", position: "fixed", top: "0", left: "0", background: "rgba(255,255,255,0.5)" }}>
          <div className="loader"></div>
        </div>
      )}
      <div className="p-lg-3 p-1">
        <div className="bg-primary-opacity shadow pb-lg-1 pb-2 cardrounded">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mx-auto  p-2">
                <div className="bg-white cardrounded border-primary border shadow-lg h-100 p-4 d-flex align-items-center">
                  <div className="text-center w-100">
                    {response !== "" && <p className="fw-bold text-info">{response}</p>}
                    {data.ratio && (
                      <>
                        <p className="text-muted py-0 my-0 fw-bold lead">Votre émission sur les 3 derniers mois a {data.ratio.toFixed(0) < 100 ? "baissé de " : "augmenté de "}</p>
                        <ColoredText ratio={data.ratio.toFixed(0)} />
                        <p className="text-muted py-0 my-0 lead">Par rapport aux 3 mois précédents</p>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mx-auto p-2">
                <div className="bg-white cardrounded contour-total shadow-lg h-100 p-4 d-flex align-items-center">
                  <div className="text-center w-100">
                    {response !== "" && <p className="fw-bold text-info">{response}</p>}
                    <p className="text-muted py-0 my-0 fw-bold lead">Votre empreinte moyenne est {userMeanRatio.toFixed(0) < 100 ? "inférieure " : "supérieure "} de</p>
                    <ColoredText ratio={userMeanRatio.toFixed(0)} />
                    <p className="text-muted py-0 my-0 lead">Par rapport à la moyenne des utilisateurs d'Ecoolo</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 mx-auto p-2">
                <div className="bg-white cardrounded contour-transport shadow-lg h-100 p-4 d-flex align-items-center">
                  <div className="text-center w-100">
                    {response !== "" && <p className="fw-bold text-info">{response}</p>}
                    <p className="text-muted py-0 my-0 fw-bold lead">Votre empreinte moyenne des transports est {userTransportRatio.toFixed(0) < 100 ? "inférieure " : "supérieure "} de</p>
                    <ColoredText ratio={userTransportRatio.toFixed(0)} />
                    <p className="text-muted py-0 my-0 lead">Par rapport à la moyenne des utilisateurs d'Ecoolo</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mx-auto p-2">
                <div className="bg-white cardrounded  contour-energy shadow-lg h-100 p-4 d-flex align-items-center">
                  <div className="text-center w-100">
                    {response !== "" && <p className="fw-bold text-info">{response}</p>}
                    <p className="text-muted py-0 my-0 fw-bold lead">Votre empreinte moyenne pour le logement est {userEnergyRatio.toFixed(0) < 100 ? "inférieure " : "supérieure "} de</p>
                    <ColoredText ratio={userEnergyRatio.toFixed(0)} />
                    <p className="text-muted py-0 my-0 lead">Par rapport à la moyenne des utilisateurs d'Ecoolo</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Evolutions;
