import React from "react";
import { useState, useEffect } from "react";
import Axios from "axios";
import { useCookies } from "react-cookie";

import { Outlet, Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
const apiUrl = process.env.REACT_APP_BACKEND_URL;
Axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
Axios.defaults.xsrfCookieName = "csrftoken";
Axios.defaults.withCredentials = true;

const VerifyEmail = (props) => {
  document.title = "Ecoolo - Sign Up";

  const [response, setResponse] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(["uToken"]);
  const [next, setNext] = useState(false);

  const [udata, setUdata] = useState({
    code: "",
  });
  const [isLoading, setIsloading] = useState(false);

  const isDataValid = () => {
    for (const key in udata) {
      if (udata.hasOwnProperty(key) && (udata[key] === "" || udata[key] === null)) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    props.setsc(true);
  }, []);

  const handlesubmit = (e) => {
    e.preventDefault();
    console.log(udata);

    if (isDataValid()) {
      setIsloading(true);
      const config = {
        headers: { "content-type": "application/json" },
      };

      Axios.post(`${apiUrl}/verify-validation-code`, udata, config)
        .then((response) => {

          toast.success("Succès");
          setIsloading(false);
          window.location.href = "/app/car_info";
        })
        .catch((error) => {
          setResponse(error.response.data.msg);
          toast.error(error.response.data.msg);
          setIsloading(false);
          console.log(error.response);
        });
    } else {
      toast.error("Form is incomplete");
    }
  };


  return (
    <>
      <Toaster />
      {isLoading && (
        <div className="d-flex align-items-center w-100 justify-content-center" style={{ height: "100lvh", zIndex: "99", position: "fixed", top: "0", left: "0", background: "rgba(255,255,255,0.5)" }}>
          <div className="loader"></div>
        </div>
      )}
      <div className="pattern d-flex align-items-center justify-content-center min-vh-100">
        <form id="uform" onSubmit={handlesubmit} className="row col-10 col-lg-4 p-4 my-5 text-dark bg-light shadow-lg border-white border-3 border cardrounded">
          {!next && (
            <div className="row">
              <div className="col-12 mb-3">
                <h3 className="fw-bold py-4">Créer un compte sur Ecoolo, c'est simple et rapide, on commence ?</h3>
                {response !== "" && <p className="fw-bold text-info">{response}</p>}
                <hr />
              </div>
              <div className="col-12 mb-3 mx-auto">
                <label for="city" className="form-label">
                  Veuillez rentrer le code de vérification envoyé à votre adresse mail
                </label>
                <input
                  required
                  type="number"
                  className="form-control bg-light text-dark shadow-sm border-1 p-3 border-secondary"
                  id="code"
                  autoComplete="on"
                  value={udata.code}
                  onChange={(e) => {
                    setUdata({ ...udata, code: e.target.value });
                  }}
                  style={{ borderRadius: "10px" }}
                />
              </div>

              <div className={`col-12 mb-3 py-2 `}>
                <button type="submit" className="btn w-100 fw-bold py-2 btn-lg btn-dark text-light">
                  Suivant <i className="fa fa-arrow-circle-right ms-3"></i>
                </button>
              </div>
            </div>
          )}
        </form>

        <Outlet />
      </div>
    </>
  );
};
export default VerifyEmail;
