import React, { useEffect, useState } from "react";
import "../styles/_master.scss"

const Contact = (props) => {
    document.title = "Ecoolo - Nous contacter";
    useEffect(() => {
        // if (cookies.uToken !== undefined) {
        //   window.location.href = "/app/";
        // }
        props.setsc(false);
    }, []);


    const MailtoLink = ({ email, subject, body, children }) => {
        const mailtoUrl = `mailto:${email}`;

        return (
            <a className="text-dark" href={mailtoUrl}>{children}</a>
        );
    };

    const email = 'contact@ecoolo.fr';

    return (
        <div>
            <div className="col-lg-8 mx-auto m-4 p-4 p-xs-4 ">
                <div className="bg-primary-opacity shadow cardrounded">
                    <div className="row m-4">
                        <div className="col-lg-12 mx-auto ">
                            <h1 className="fw-bold py-4 text-center">Nous contacter</h1>
                            <p className="text-center">Ecoolo est une application qui <strong>évolue constamment pour offir les meilleurs fonctionnalités</strong> à ses utilisateurs.</p>
                            <p className="text-center">Si vous souhaitez nous contacter pour nous donner vos impressions, des suggestions pour améliorer l'application. Ecrivez-nous à:</p>
                            <br></br>
                            <div className="text-center fs-2 p-4">

                                <MailtoLink email={email}>
                                    contact@ecoolo.fr
                                </MailtoLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>


    );
};

export default Contact;