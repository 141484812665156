import React from "react";
import { Outlet, Link } from "react-router-dom";
const Card = (props) => {
  const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"];

  function handleDetail() {
    localStorage.setItem("detdata", JSON.stringify(props.data));
    window.location.href = "/app/detail";
  }

  return (
    <div>
      <div class="card w-100 border-0 shadow p-2 bg-primary position-relative cardrounded">
        <img
          src="https://img.freepik.com/vecteurs-libre/concept-environnemental-dans-style-du-papier-ville-moulins-vent_52683-32821.jpg?t=st=1709729810~exp=1709733410~hmac=a933d4abde65153d3f51d96a8ad648d43405a43088996811dac2a235404af015&w=1380"
          class="card-img-top border-bottom "
          style={{ borderRadius: "2rem 2rem 0 0 " }}
          alt="..."
        />
        <div class="card-body bg-light" style={{ borderRadius: "0 0 2rem 2rem " }}>

          <div className="text-center">
            <span className="bg-primary p-3 rounded-circle text-white fs-4 my-2">
              <i className="fa fa-cloud"></i>
            </span>
            <h5 className="fw-bold fs-3 mb-0 pb-0 mt-3 text-dark"> {props.data.monthly_footprint.toFixed(0)}</h5>
            <p class="text-lead fw-bold  text-muted ">
              kg CO<sub>2</sub>
            </p>

          </div>

          <button onClick={handleDetail} class="button-64 w-100">
            Détail du mois
          </button>
        </div>

        <div className="position-absolute  bg-primary cardrounded px-4 py-2 text-center" style={{ top: "100px", left: "50%", transform: "translateX(-50%)" }}>
          <h4 className="fs-2 fw-bold text-white p-1 px-3 w-100 mb-0 pb-0"> {props.data.year} </h4>
          <h4 className="fs-1 fw-bold text-primary p-1 px-3 w-100 mt-0 pt-0 whiteStroke">{months[props.data.month - 1]}</h4>
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default Card;
